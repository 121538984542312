import React, { useState } from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { Paper, useTheme, alpha } from '@mui/material';
// eslint-disable-next-line import/no-unresolved
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useLocation, Link } from 'react-router-dom';

import { PATHS } from '../../../routes/paths';
import { BottomNavMenu } from './BottomNavMenu';
import { ROUTE_PATHS } from '../../constants';

export const BottomNav = () => {
  // eslint-disable-next-line no-unused-vars
  const [openMenu, setOpenMenu] = useState(false);
  const [, setValue] = useState(0);
  const { pathname } = useLocation();
  const theme = useTheme();
  const handleClickOpen = () => {
    setOpenMenu(true);
  };

  const isMoreRoutesActive = !(
    pathname === PATHS.root ||
    pathname === PATHS.games ||
    pathname === PATHS.genX
  );

  return (
    <Paper
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: theme.zIndex.modal - 1,
      }}
      elevation={3}
    >
      <BottomNavigation
        sx={{ bgcolor: 'grey.900' }}
        showLabels
        value={pathname}
        onChange={(e, newVal) => {
          setValue(newVal);
        }}
      >
        {ROUTE_PATHS.slice(0, 3).map(({ icon: Icon, id, name, path }) => (
          <BottomNavigationAction
            color="success"
            sx={{
              whiteSpace: 'nowrap',
              color: '#fff',
              '.Mui-selected': {
                color: 'primary.light',
              },
              ...(path === pathname && {
                bgcolor: alpha(theme.palette.primary.main, 0.1),
                borderTop: `2px solid ${theme.palette.primary.main}`,
              }),
            }}
            component={Link}
            to={path}
            value={path}
            key={id}
            label={name}
            icon={
              <Icon
                color={path === pathname ? theme.palette.primary.light : '#fff'}
              />
            }
          />
        ))}
        <BottomNavigationAction
          onClick={handleClickOpen}
          id="more-button"
          label="more"
          sx={{
            color: isMoreRoutesActive ? theme.palette.primary.light : '#fff',
            bgcolor:
              isMoreRoutesActive && alpha(theme.palette.primary.main, 0.1),
            borderTop:
              isMoreRoutesActive && `3px solid ${theme.palette.primary.main}`,
            '.Mui-selected': {
              color: 'primary.light',
            },
          }}
          icon={
            <MoreHorizIcon
              sx={{
                color: isMoreRoutesActive
                  ? theme.palette.primary.light
                  : 'inherit',
              }}
            />
          }
        />
      </BottomNavigation>
      <BottomNavMenu
        open={openMenu}
        handleClose={() => setOpenMenu(false)}
        onOpen={() => setOpenMenu(true)}
      />
    </Paper>
  );
};
