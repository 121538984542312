import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const homePageApi = createApi({
  reducerPath: 'homePageApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `https://woj-platform.herokuapp.com`,
  }),
  endpoints: (builder) => ({
    homepageData: builder.query({
      query: () => ({
        url: '/initialize',
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    chart: builder.query({
      query: () => ({
        url: '/chart',
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    activationProgress: builder.query({
      query: () => ({
        url: '/actives',
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
  }),
});

export const {
  useHomepageDataQuery,
  useChartQuery,
  useActivationProgressQuery,
} = homePageApi;
