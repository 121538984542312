import {
  useAccount,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from 'wagmi';
import { getBigNumber } from '../../../utils';

export const useClaimReward = ({
  claimAmount,
  address,
  abi,
  onSubmittedTransaction,
  onSuccessTransaction,
  onSettledTransaction,
  onErrorSubmittedTransaction,
  onErrorTransaction,
  isWusd = false,
}) => {
  const { address: walletAddress } = useAccount();

  const {
    config,
    error: prepareError,
    isError: isPrepareError,
  } = usePrepareContractWrite({
    overrides: { gasPrice: getBigNumber('4', 'gwei'), gasLimit: 550000 },
    address,
    abi,
    functionName: isWusd ? 'claimWUSD' : 'claimReward',
    enabled: Boolean(walletAddress),
    ...(claimAmount && { args: [claimAmount] }),
  });

  const {
    data: claimData,
    isLoading: isLoadingWriteContract,
    isSuccess: isSuccessWriteContract,
    write,
  } = useContractWrite({
    ...config,
    onSuccess() {
      onSubmittedTransaction?.();
    },
    onError(error) {
      onErrorSubmittedTransaction?.(error);
    },
  });

  const {
    isLoading: isLoadingWaitForTransaction,
    data: transactionReceipt,
    isSuccess: isSuccessTransaction,
    isError: isErrorTransaction,
    error: errorTransaction,
  } = useWaitForTransaction({
    hash: claimData?.hash,

    onSuccess(data) {
      onSuccessTransaction?.(data);
    },
    onSettled() {
      onSettledTransaction?.();
    },
    onError(error) {
      onErrorTransaction?.(error);
    },
  });

  return {
    hash: claimData?.hash,
    prepareError,
    isLoadingWriteContract,
    isSuccessWriteContract,
    write,
    isLoadingWaitForTransaction,
    transactionReceipt,
    isSuccessTransaction,
    isPrepareError,
    claimData,
    isErrorTransaction,
    errorTransaction,
  };
};
