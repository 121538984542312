import queryString from 'query-string';

export function sharePost(text) {
  const baseUrl = 'https://x.com/share';
  const query = { url: '  ', text };

  const url = queryString.stringifyUrl({ url: baseUrl, query });

  return url;
}
