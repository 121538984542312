import BoltIcon from '../../../assets/svg/BoltIcon';
import { BlackButton, PrimaryDarkButton } from './styles';

export function ChargeAllButton({ sx, children, ...props }) {
  return (
    <BlackButton
      size="small"
      startIcon={EnergyIcon}
      sx={{ px: 1.5, ...sx }}
      {...props}
    >
      {children}
    </BlackButton>
  );
}

export function ChargeSingleButton({
  sx,
  children,
  withoutIcon = false,
  ...props
}) {
  return (
    <PrimaryDarkButton
      size="small"
      startIcon={!withoutIcon && EnergyIcon}
      sx={{ px: 1.5, ...sx }}
      {...props}
    >
      {children}
    </PrimaryDarkButton>
  );
}
const EnergyIcon = (
  <BoltIcon
    sx={{
      display: 'flex',
      width: '1.1rem',
      height: '1.1rem',
      cursor: 'pointer',
    }}
  />
);
