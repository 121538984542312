import { USDT_ABI } from '../../ABI';
import { USDT_LOGO } from '../../assets/img';
import { BabyWojCoinIcon, WojCoinIcon } from '../../assets/svg';
import { BABY_WOJ_ABI, WOJ_ABI } from '../abi';

export const tokens = [
  {
    name: 'WOJ Token',
    symbol: 'WOJ',
    address: '0x55F96C7005D7C684A65Ee653B07b5FE1507C56ab',
    chainId: 56,
    decimals: 9,
    logoURI: WojCoinIcon,
    abi: WOJ_ABI,
  },
  {
    name: 'BWJ Token',
    symbol: 'BWJ',
    address: '0x83F41c98d028842Ccc8060b4Ec7738Df3eb9a2e6',
    chainId: 56,
    decimals: 9,
    logoURI: BabyWojCoinIcon,
    abi: BABY_WOJ_ABI,
  },

  {
    name: 'USDT Token',
    symbol: 'USDT',
    address: '0x55d398326f99059fF775485246999027B3197955',
    chainId: 56,
    decimals: 18,
    logoURI: USDT_LOGO,
    abi: USDT_ABI,
  },
];
