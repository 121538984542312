/* eslint-disable camelcase */
export function energyUp({ builder, isGenx = false }) {
  return builder.mutation({
    query: ({ token_address, token_id, percent }) => ({
      url: isGenx ? '/genx/energy-up' : '/transaction/energy-up',
      method: 'POST',
      body: {
        token_address,
        token_id,
        percent,
      },
    }),
  });
}
