import clsx from 'clsx';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import AddIcon from '@mui/icons-material/Add';
import { USDT_LOGO, WUSD_LOGO } from '../../../../assets/img';

export function SelectCurrency() {
  return (
    <div className="flex justify-center items-center gap-x-2 min-w-[320px]">
      <PaymentButton
        className="!border-success-light bg-usdt/10"
        currency="USDT"
      />
      <AddIcon className="w-4 h-4" />
      <PaymentButton className="!border-wusd bg-wusd/10" currency="WUSD" />
    </div>
  );
}

function PaymentButton({ currency, className, ...props }) {
  const src = currency === 'USDT' ? USDT_LOGO : WUSD_LOGO;

  return (
    <div
      className={clsx(
        'flex items-center gap-x-2 outline-none rounded-xl py-1.5 px-2.5 md:py-2 md:px-4 border border-white/10 transition-colors duration-200',
        className
      )}
      {...props}
    >
      <LazyLoadImage
        className="rounded-full"
        width={28}
        height={28}
        src={src}
        alt={currency}
      />
      <span className="uppercase">{currency}</span>
    </div>
  );
}
