// ----------------------------------------------------------------------

import { ABOVE_TIDIO_ZINDEX } from '../../utils/commonValues';

export default function Popover(theme) {
  return {
    MuiPopover: {
      styleOverrides: {
        root: { zIndex: ABOVE_TIDIO_ZINDEX + 1 },
        paper: {
          boxShadow: theme.customShadows.dropdown,
          borderRadius: Number(theme.shape.borderRadius) * 1.5,
        },
      },
    },
  };
}
