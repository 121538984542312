import { Box, IconButton, Typography, useTheme } from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';

import CloseIcon from '@mui/icons-material/Close';
import { Link, useLocation } from 'react-router-dom';
// eslint-disable-next-line import/no-unresolved
import { useModal } from 'connectkit';
import clsx from 'clsx';
import { useAccount, useDisconnect } from 'wagmi';
import { SwipeableDrawer } from '../../../../components';
import { ROUTE_PATHS } from '../../../constants';

export function BottomNavMenu({ open, handleClose, onOpen }) {
  const { pathname } = useLocation();
  const { disconnect } = useDisconnect();
  const theme = useTheme();
  const { isConnected } = useAccount();
  const { openProfile: openModal } = useModal();

  return (
    <SwipeableDrawer
      onOpen={onOpen}
      id="navigation-dialog"
      open={open}
      onClose={handleClose}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          px: 1,
        }}
      >
        <Typography variant="h4">More</Typography>
        <IconButton onClick={handleClose} sx={{ color: '#fff', p: 0 }}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box
        sx={{
          my: 2,
          display: 'flex',
          flexDirection: 'column',
          rowGap: 1.5,
        }}
      >
        {ROUTE_PATHS.slice(3, ROUTE_PATHS.length).map(
          ({ icon: Icon, id, name, path }) => (
            <Link
              onClick={handleClose}
              key={id}
              className={clsx(
                'flex items-center gap-x-2 p-2 no-underline rounded-lg text-white bg-transparent',
                {
                  'text-secondary': path === pathname,
                  'bg-secondary/20': path === pathname,
                }
              )}
              to={path}
            >
              <Icon
                color={
                  path === pathname ? theme.palette.secondary.main : '#fff'
                }
              />
              <span>{name}</span>
            </Link>
          )
        )}
        <button
          onClick={() => {
            handleClose();
            if (isConnected) {
              disconnect?.();
              return;
            }
            openModal?.();
          }}
          type="button"
          className={clsx(
            'flex items-center gap-x-2 p-2 no-underline rounded-lg text-white bg-transparent'
          )}
        >
          {isConnected ? (
            <>
              <LogoutIcon /> <span>Disconnect</span>
            </>
          ) : (
            <>
              <LoginIcon /> <span>Login</span>
            </>
          )}
        </button>
      </Box>
    </SwipeableDrawer>
  );
}
