import { SwiperSlide } from 'swiper/react';
import { times } from 'lodash';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import { SwiperProvider } from '../../swiper';
import { RewardCard, RewardCardSkeleton } from '../RewardCard';
import { ClaimAll } from './ClaimAll';
import { useBreakpointUp } from '../../../hooks';

export function RewardsSlider({
  rewardsQuery,
  type,
  onClaimClick,
  claimDisabled,
  isLoadingAnyState,
  paymentToken,
  setPaymentToken,
}) {
  const isUpSm = useBreakpointUp('sm');
  const noRewards = rewardsQuery?.data?.items.length === 0;

  if (
    rewardsQuery?.isLoading ||
    (!rewardsQuery?.isError && !rewardsQuery?.data)
  ) {
    return (
      <SwiperProvider>
        {times(10).map((id) => (
          <SwiperSlide key={id} className="max-w-[260px] last:mr-8">
            <RewardCardSkeleton />
          </SwiperSlide>
        ))}
      </SwiperProvider>
    );
  }

  if (rewardsQuery?.isError) {
    return (
      <div className="w-full flex flex-col gap-y-6 items-center justify-center h-60 bg-tertiary-darkest rounded-xl">
        <SentimentDissatisfiedIcon className="text-6xl" />
        <p className="font-bold text-xl">An Error occurred</p>
      </div>
    );
  }

  return (
    <div className="flex items-stretch gap-x-8">
      {isUpSm && (
        <ClaimAll
          isLoadingAnyState={isLoadingAnyState}
          className="min-w-[400px]"
          claimDisabled={claimDisabled}
          onClaimClick={onClaimClick}
          rewardsQuery={rewardsQuery}
          paymentToken={paymentToken}
          setPaymentToken={setPaymentToken}
          type={type}
        />
      )}
      <SwiperProvider>
        {!isUpSm && (
          <SwiperSlide className="max-w-[350px]">
            <ClaimAll
              isLoadingAnyState={isLoadingAnyState}
              claimDisabled={claimDisabled}
              onClaimClick={onClaimClick}
              rewardsQuery={rewardsQuery}
              paymentToken={paymentToken}
              setPaymentToken={setPaymentToken}
              type={type}
            />
          </SwiperSlide>
        )}
        {!noRewards &&
          rewardsQuery?.data?.items.map(({ amount, date, id, collection }) => (
            <SwiperSlide key={id} className="max-w-[260px] last:mr-8">
              <RewardCard
                paymentToken={paymentToken}
                date={date}
                amount={amount}
                className="h-full"
                collection={collection}
              />
            </SwiperSlide>
          ))}
      </SwiperProvider>
    </div>
  );
}
