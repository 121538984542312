import { useAccount, useContractRead } from 'wagmi';

export function useGetUserReward({ address, abi, isWusd = false }) {
  const { address: walletAddress } = useAccount();
  return useContractRead({
    address,
    abi,
    functionName: isWusd ? 'rewardsWUSD' : 'userReward',
    args: [walletAddress],
    enabled: Boolean(walletAddress),
    watch: true,
  });
}
