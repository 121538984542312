import { Box, IconButton } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';

export function WarningAlert({ onClick, size = 'small', ...props }) {
  return (
    <Box
      width="2.5rem"
      height="2.5rem"
      borderRadius="0.75rem"
      display="flex"
      justifyContent="center"
      alignItems="center"
      bgcolor="warning.main"
      {...props}
    >
      <IconButton
        disableRipple
        size={size}
        sx={{ color: '#fff', bgcolor: 'transparent' }}
        onClick={onClick}
      >
        <WarningIcon />
      </IconButton>
    </Box>
  );
}
