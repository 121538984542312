import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useAccount } from 'wagmi';
import {
  selectCurrentVerifyToken,
  selectGetUsersError,
  selectGetUsersLoading,
} from '../../../redux/slices/authSlice';
import { Api } from '../../services';
import { FLAGS } from '../../../constants';

export function useGetGenxNFTs() {
  const [paginationCursor] = useState('');

  const limit = 100;
  const token = useSelector(selectCurrentVerifyToken);
  const usersError = useSelector(selectGetUsersError);
  const usersLoading = useSelector(selectGetUsersLoading);
  const { isConnected } = useAccount();
  const skip =
    !isConnected ||
    !token ||
    usersLoading ||
    usersError ||
    FLAGS.IS_UNDER_MAINTENANCE;
  return Api.useGetGenxNFTsQuery(
    { limit, cursor: paginationCursor },
    {
      skip,
    }
  );
}
