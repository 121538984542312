import { Swiper } from 'swiper/react';

export function SwiperProvider({ children, ...props }) {
  return (
    <Swiper
      className="w-full"
      slidesPerView="auto"
      spaceBetween={16}
      {...props}
    >
      {children}
    </Swiper>
  );
}
