import { Avatar, Box, Typography, alpha } from '@mui/material';
import { FormattedNumber } from 'react-intl';
import { utils } from 'ethers';
import {
  useGetTotalReward,
  useGetTotalStakes,
  useGetUserStakeCount,
} from '../contracts';
import { WojCoinIcon } from '../../../assets/svg';

export function StakingStats() {
  const { data: totalStakes } = useGetTotalStakes();
  const { data: totalReward } = useGetTotalReward();
  const { data: stakeCount } = useGetUserStakeCount();

  return (
    <Box
      display="flex"
      alignItems="center"
      bgcolor="grey.900"
      borderRadius={2}
      width="100%"
    >
      <SingleStat
        amount={stakeCount ? utils.formatUnits(stakeCount, 0) : 0}
        title="Number of Stakes"
        withoutLogo
      />
      <SingleStat
        amount={totalStakes ? utils.formatUnits(totalStakes, 9) : 0}
        title="Total Stakes"
        sx={{
          borderLeft: `1px solid ${alpha('#fff', 0.1)}`,
          borderRight: `1px solid ${alpha('#fff', 0.1)}`,
          px: 3,
        }}
      />
      <SingleStat
        amount={totalReward ? utils.formatUnits(totalReward, 9) : 0}
        title="Total Rewards"
      />
    </Box>
  );
}

function SingleStat({
  title,
  amount,
  amountColor = '#fff',
  amountStyle,
  withoutLogo = false,
  sx,
}) {
  return (
    <Box
      padding={1}
      display="flex"
      width="100%"
      flexDirection="column"
      alignItems="center"
      rowGap={1}
      sx={sx}
    >
      <Typography align="center" variant="body2" color="text.secondary">
        {title}
      </Typography>
      <Box display="flex" alignItems="center" columnGap={0.5}>
        {!withoutLogo && (
          <Avatar
            src={WojCoinIcon}
            sx={{ width: '1.2rem', height: '1.2rem' }}
          />
        )}
        <Typography variant="h6" color={amountColor}>
          <FormattedNumber style={amountStyle} value={amount} />
        </Typography>
      </Box>
    </Box>
  );
}
