/* eslint-disable camelcase */
export function setProfile({ builder, isGenx = false, isFreeMint = false }) {
  return builder.mutation({
    query: ({ token_address, token_id, twitter, token_hash }) => ({
      url: isGenx
        ? '/genx/twitter-profile'
        : isFreeMint
        ? '/free-mint/twitter-profile'
        : '/user/twitter-profile',
      method: 'POST',
      body: {
        token_address,
        token_id,
        twitter,
        token_hash,
      },
    }),
  });
}
