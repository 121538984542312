import {
  LinearProgress as MuiLinearProgress,
  linearProgressClasses,
  styled,
} from '@mui/material';

export const LinearProgress = styled(MuiLinearProgress, {
  shouldForwardProp: (prop) => prop !== 'barColor' && prop !== 'primaryColor',
})(({ theme, barColor, primaryColor = theme.palette.grey[800] }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: primaryColor,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: barColor,
  },
}));
