import { Box, Divider, Typography } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { NavLink } from './styles';
import { PATHS } from '../../../routes/paths';
import { ROUTE_PATHS } from '../../constants';

export function SidebarNavigation() {
  const { pathname } = useLocation();

  const {
    path: homePath,
    icon: HomeIcon,
    name: homeName,
  } = ROUTE_PATHS.find((item) => item.name === 'Home');

  const {
    path: faqPath,
    icon: FaqIcon,
    name: faqName,
  } = ROUTE_PATHS.find((item) => item.name === 'Faq');

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        px: 1,
        rowGap: 2,
      }}
    >
      {/* Home */}
      <NavLink
        component={Link}
        to={homePath}
        path={homePath}
        pathname={pathname}
      >
        <HomeIcon />
        <Typography variant="body2" fontWeight={700}>
          {homeName}
        </Typography>
      </NavLink>
      <Divider />

      {/* Rest */}

      {ROUTE_PATHS.filter(
        (item) => item.path !== PATHS.root && item.path !== PATHS.faq
      ).map(({ id, icon: Icon, name, path }) => (
        <NavLink key={id} to={path} path={path} pathname={pathname}>
          <Icon />
          <Typography variant="body2" fontWeight={700}>
            {name}
          </Typography>
        </NavLink>
      ))}
      <Divider />

      {/* Faq */}

      <NavLink component={Link} to={faqPath} path={faqPath} pathname={pathname}>
        <FaqIcon />
        <Typography variant="body2" fontWeight={700}>
          {faqName}
        </Typography>
      </NavLink>
    </Box>
  );
}
