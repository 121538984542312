import { DialogActions, Typography } from '@mui/material';
import { CustomButton } from '../../../games/modals/styles';
import Header from '../../../games/modals/Header';
import { Modal } from '../../..';

export function StakeInfoModal({ open, handleClose }) {
  return (
    <Modal open={open} onClose={handleClose} aria-describedby="success-modal">
      <Header handleClose={handleClose} content="Staking Informations" />
      <Typography paragraph>
        Please note, for this staking activity, your funds are only accessible
        upon the completion of a full 365-day cycle, and there is no provision
        for an early cancellation.
      </Typography>
      <Typography>
        Whenever new members join your community by using your active or
        personally generated codes and stake their Woj tokens, a 3% reward is
        immediately initiated. This reward, predicated on their staked Woj token
        amount, is automatically credited to you in the form of Woj tokens.
      </Typography>
      <DialogActions sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        <CustomButton
          onClick={handleClose}
          variant="contained"
          className="bg-primary-dark"
        >
          I understand
        </CustomButton>
      </DialogActions>
    </Modal>
  );
}
