import {
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from 'wagmi';
import { bsc } from '@wagmi/chains';
import { utils } from 'ethers';
import { useSnackbar } from 'notistack';
import { CLAIM_TOKEN_ABI } from '../../../ABI';
import { CLAIM_TOKEN_ADDRESS } from '../../../components/page-rewards/constants';
import { Api } from '../../services';
import { getBigNumber } from '../../../utils';

function mapToBigNumber(arrayOfNumbers) {
  return arrayOfNumbers.map((number) => utils.parseUnits(`${number}`, 0));
}

export function useClaimTokenRewards({
  onSettledTransaction,
  onSubmittedTransaction,
  onSuccessTransaction,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [
    getPrepareData,
    {
      data: prepareData,
      isError: isErrorBeforePrepareContract,
      isLoading: isLoadingBeforePrepareContract,
      isSuccess: isSuccessBeforePrepareContract,
      error: beforePrepareContractError,
    },
  ] = Api.useClaimRewardMutation();

  const [_id, _amount, _expireAt] = mapToBigNumber(
    prepareData
      ? [prepareData.id, prepareData.amount, prepareData.expireAt]
      : []
  );

  const {
    config,
    isLoading: isLoadingPrepareContract,
    isSuccess: isSuccessPrepareContract,
    isError: isErrorPrepareContract,
    error: prepareContractError,
  } = usePrepareContractWrite({
    overrides: { gasPrice: getBigNumber('4', 'gwei'), gasLimit: 550000 },
    abi: CLAIM_TOKEN_ABI,
    functionName: 'claimToken',
    args: prepareData
      ? [prepareData.address, _id, _amount, _expireAt, prepareData.signature]
      : undefined,
    enabled: Boolean(prepareData) && isSuccessBeforePrepareContract,
    address: CLAIM_TOKEN_ADDRESS,
    chainId: bsc.id,

    onError(error) {
      enqueueSnackbar(error.reason, { variant: 'error' });
    },
  });

  const {
    write,
    isLoading: isLoadingWriteContract,
    data,
    isSuccess: isSuccessWrite,
    error: contractError,
    isError: isErrorContractError,
    reset,
  } = useContractWrite({
    ...config,
    onSuccess() {
      onSubmittedTransaction?.();
    },
  });

  const {
    isLoading: isLoadingWaitForTransaction,
    data: transactionReceipt,
    isSuccess: isSuccessTransaction,
    error: waitForTransactionError,
  } = useWaitForTransaction({
    hash: data?.hash,
    onSuccess(data) {
      onSuccessTransaction?.(data);
    },
    onSettled() {
      onSettledTransaction?.();
    },
    onError(error) {
      enqueueSnackbar(error.reason, { variant: 'error' });
    },
  });

  const isLoadingAnyState =
    isLoadingBeforePrepareContract ||
    isLoadingPrepareContract ||
    isLoadingWriteContract ||
    isLoadingWaitForTransaction;

  const isErrorAnyState =
    isErrorPrepareContract ||
    isErrorBeforePrepareContract ||
    isErrorContractError;

  return {
    hash: data?.hash,
    isLoadingAnyState,
    isLoadingWaitForTransaction,
    isLoadingWriteContract,
    isSuccessPrepareContract,
    isErrorAnyState,
    isSuccessTransaction,
    isSuccessWrite,
    beforePrepareContractError,
    errors: [prepareContractError, contractError, waitForTransactionError],
    getPrepareData,
    reset,
    transactionReceipt,
    write,
  };
}
