import LoginIcon from '@mui/icons-material/Login';
import { Box, Typography } from '@mui/material';
// eslint-disable-next-line import/no-unresolved
import { ConnectKitButton } from 'connectkit';
import { useAccount } from 'wagmi';
import { useDispatch, useSelector } from 'react-redux';
import {
  openActivationDialog,
  selectCurrentToken,
  selectIsActive,
} from '../../redux/slices/authSlice';
import { PrimaryDarkButton } from '../ui';

import { pxToRem } from '../../utils/getFontValue';

export function NotConnected({ withActivationGuard = false }) {
  const isActive = useSelector(selectIsActive);
  const { isConnected } = useAccount();
  const token = useSelector(selectCurrentToken);
  const dispatch = useDispatch();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        mt: '5rem',
        rowGap: 3,
      }}
    >
      <Typography
        variant="h3"
        align="center"
        sx={{
          mt: {
            xs: 1.5,
            md: 3,
          },
        }}
      >
        {isConnected && token && !isActive && withActivationGuard
          ? 'Activation'
          : 'Connect To Platform'}
      </Typography>
      {isConnected && token && !isActive && withActivationGuard ? (
        <PrimaryDarkButton
          sx={{ width: pxToRem(300), py: 1.5, typography: 'h6' }}
          onClick={() => dispatch(openActivationDialog())}
          variant="contained"
        >
          Activate your account
        </PrimaryDarkButton>
      ) : (
        <ConnectKitButton.Custom>
          {({ show, truncatedAddress, isConnected }) => (
            <PrimaryDarkButton
              startIcon={<LoginIcon />}
              sx={{ width: pxToRem(300), py: 1.5, typography: 'h6' }}
              onClick={show}
              variant="contained"
            >
              {isConnected ? truncatedAddress : 'Log in with your wallet'}
            </PrimaryDarkButton>
          )}
        </ConnectKitButton.Custom>
      )}
    </Box>
  );
}
