import clsx from 'clsx';
import Countdown from 'react-countdown';

const HR = () => <span className="flex w-[2px] bg-white bg-opacity-10" />;

export function MintCountdownTimer({ date, className, ...props }) {
  return (
    <div>
      <Countdown
        date={date}
        renderer={({ days, hours, minutes, seconds, completed }) => {
          if (completed) {
            return null;
          }
          return (
            <div className={clsx('flex gap-x-2 font-semibold', className)}>
              <TimeSegment time={days}>days</TimeSegment>
              <HR />
              <TimeSegment time={hours}>hours</TimeSegment>
              <HR />
              <TimeSegment time={minutes}>minutes</TimeSegment>
              <HR />
              <TimeSegment time={seconds}>seconds</TimeSegment>
            </div>
          );
        }}
        {...props}
      />
    </div>
  );
}

export function TimeSegment({ time, children, className, ...props }) {
  return (
    <div
      className={clsx(
        'flex flex-col items-center justify-center',
        {
          'min-w-[35px] md:min-w-[55px]': Boolean(children),
        },
        className
      )}
      {...props}
    >
      <span>{time}</span>
      {children && (
        <span className="text-[0.6em] font-normal opacity-70">{children}</span>
      )}
    </div>
  );
}
