export const healthColor = (health) => {
  if (health <= 70 && health > 50) return '#D8DC29';
  if (health <= 50 && health > 20) return '#EF3281';
  if (health <= 20) return '#EF3232';
  return '#54D62C';
};
export const monarchHealthColor = (health) => {
  if (health <= 35 && health > 25) return '#D8DC29';
  if (health <= 25 && health > 10) return '#EF3281';
  if (health <= 10) return '#EF3232';
  return '#54D62C';
};

export const amountsPercentageColor = (percentage) => {
  if (percentage <= 70 && percentage > 50) return '#D8DC29';
  if (percentage <= 50 && percentage > 20) return '#EF3281';
  if (percentage <= 20) return '#EF3232';
  return '#54D62C';
};
