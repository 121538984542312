export function activateUser({ builder, isGenx = false }) {
  return builder.mutation({
    query: ({ code }) => ({
      url: isGenx ? '/user/genx/activate' : '/user/activate',
      method: 'POST',
      body: {
        code,
      },
    }),
  });
}
