export function checkTask({ builder, isGenx = false }) {
  return builder.query({
    query: ({ id }) => ({
      url: isGenx
        ? `/user/genx/task/${id}/inquiry`
        : `/user/task/${id}/inquiry`,
    }),
    invalidatesTags: ['Tasks'],
  });
}
