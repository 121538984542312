import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

export function MintActions({ mintAmount, setMintAmount }) {
  return (
    <div className="flex flex-col gap-y-2">
      <span>Amount of NFTs</span>{' '}
      <div className="flex p-2 gap-x-2 border border-white/10 rounded-md">
        <ActionButton
          mintAmount={mintAmount}
          setMintAmount={setMintAmount}
          type="decrease"
        />
        <div className="flex-1 flex items-center justify-center font-bold text-lg">
          {mintAmount}
        </div>
        <ActionButton
          mintAmount={mintAmount}
          type="increase"
          setMintAmount={setMintAmount}
        />
      </div>
    </div>
  );
}

function ActionButton({ type, setMintAmount, mintAmount }) {
  function decreaseMintAmount() {
    if (mintAmount <= 0) return;
    setMintAmount((prev) => prev - 1);
  }
  function increaseMintAmount() {
    if (mintAmount >= 50) return;
    setMintAmount((prev) => prev + 1);
  }

  function onClick() {
    if (type === 'increase') {
      increaseMintAmount();
    } else {
      decreaseMintAmount();
    }
  }

  return (
    <button
      className="w-7 h-7 rounded-md bg-primary-light flex items-center justify-center !outline-none"
      onClick={onClick}
      type="button"
    >
      {type === 'increase' ? <AddIcon /> : <RemoveIcon />}
    </button>
  );
}
