import React from 'react';

export function SwapIcon({
  color = '#fff',
  width = 24,
  height = 24,
  ...props
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill={color}
      {...props}
    >
      <path
        d="M10.3265 13.8403C10.1998 13.8403 10.0731 13.7936 9.97314 13.6936C9.77981 13.5003 9.77981 13.1803 9.97314 12.987L13.3131 9.64695C13.5065 9.45362 13.8265 9.45362 14.0198 9.64695C14.2131 9.84029 14.2131 10.1603 14.0198 10.3536L10.6798 13.6936C10.5798 13.787 10.4531 13.8403 10.3265 13.8403Z"
        fill={color}
      />
      <path
        d="M13.6667 10.4932H2.33333C2.05999 10.4932 1.83333 10.2665 1.83333 9.99316C1.83333 9.71983 2.05999 9.49316 2.33333 9.49316H13.6667C13.94 9.49316 14.1667 9.71983 14.1667 9.99316C14.1667 10.2665 13.94 10.4932 13.6667 10.4932Z"
        fill={color}
      />
      <path
        d="M2.33297 6.5063C2.20631 6.5063 2.07964 6.45964 1.97964 6.35964C1.78631 6.1663 1.78631 5.8463 1.97964 5.65297L5.31964 2.31297C5.51297 2.11964 5.83297 2.11964 6.02631 2.31297C6.21964 2.5063 6.21964 2.8263 6.02631 3.01964L2.68631 6.35964C2.59297 6.45297 2.45964 6.5063 2.33297 6.5063Z"
        fill={color}
      />
      <path
        d="M13.6667 6.50684H2.33333C2.05999 6.50684 1.83333 6.28017 1.83333 6.00684C1.83333 5.7335 2.05999 5.50684 2.33333 5.50684H13.6667C13.94 5.50684 14.1667 5.7335 14.1667 6.00684C14.1667 6.28017 13.94 6.50684 13.6667 6.50684Z"
        fill={color}
      />
    </svg>
  );
}
