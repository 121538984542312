/* eslint-disable camelcase */
export function levelUp({ builder, isGenx = false }) {
  return builder.mutation({
    query: ({ token_address, token_id }) => ({
      url: isGenx ? '/genx/level-up' : '/transaction/level-up',
      method: 'POST',
      body: {
        token_address,
        token_id,
      },
    }),
  });
}
