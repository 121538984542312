import { Box, useMediaQuery } from '@mui/material';
import Countdown from 'react-countdown';
import TimeSegment from './TimeSegment';

const HR = () => (
  <Box component="span" sx={{ display: 'flex', width: 2, bgcolor: '#fff' }} />
);

const CountdownTimer = ({ date, onlyDays, ...props }) => {
  const xsSize = useMediaQuery('(max-width:355px)');

  return (
    <div>
      <Countdown
        date={date}
        renderer={({ days, hours, minutes, seconds, completed }) => {
          if (completed) {
            return <span>{'finished'}</span>;
          }

          return (
            <Box
              sx={{
                display: 'flex',
                columnGap: xsSize ? 0.5 : 1,
                bgcolor: 'grey.900',
                px: xsSize ? 0.5 : 1,
                py: onlyDays ? 0 : 1,
                borderRadius: 2,
              }}
            >
              {onlyDays ? (
                <TimeSegment horizontal time={days} onlyDays>
                  {'days'}
                </TimeSegment>
              ) : (
                <>
                  <TimeSegment time={days}>{'days'}</TimeSegment>
                  <HR />
                  <TimeSegment time={hours}>{'hours'}</TimeSegment>
                  <HR />
                  <TimeSegment time={minutes}>{'minutes'}</TimeSegment>
                  <HR />
                  <TimeSegment time={seconds}>{'seconds'}</TimeSegment>
                </>
              )}
            </Box>
          );
        }}
        {...props}
      />
    </div>
  );
};

export default CountdownTimer;
