export function getEnergyPrice({ builder, isGenx = false }) {
  return builder.query({
    query: ({ currency }) => ({
      url: isGenx ? '/genx/nft/energy/price' : `/nft/energy/price`,
      params: {
        currency,
      },
    }),
  });
}
