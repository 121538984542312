import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { useState } from 'react';
import { CircularProgress } from '@mui/material';
import { FormattedNumber } from 'react-intl';
import CheckIcon from '@mui/icons-material/Check';
import { Modal } from '../../../ui';
import { useBreakpointDown } from '../../../../hooks';
import { TweetStep } from './TweetStep';
import { CustomButton } from '../../../games/modals/styles';
import { VerifyStep } from './VerifyStep';
import { ClaimStep } from './ClaimStep';

const steps = ['Tweet', 'Verify', 'Claim reward'];

export function TweetModal({
  open,
  onClose,
  onClaimClick,
  amount,
  claimLoading,
  ...props
}) {
  const [activeStep, setActiveStep] = useState(0);
  const isDownSm = useBreakpointDown('sm');
  function handleButtonText() {
    switch (activeStep) {
      case 1:
        return 'Verify';

      case 2:
        return (
          <span>
            <FormattedNumber value={amount} /> Claim
          </span>
        );

      default:
        return 'Next Step';
    }
  }

  return (
    <Modal open={open} onClose={onClose} title="Claim Rewards" {...props}>
      <div className="w-full flex flex-col gap-y-10">
        <Stepper
          orientation={isDownSm ? 'vertical' : 'horizontal'}
          activeStep={activeStep}
        >
          {steps.map((label, index) => (
            <Step active={index === activeStep} key={label}>
              <StepLabel
                icon={
                  index < activeStep ? (
                    <span className="bg-primary flex items-center justify-center rounded-full w-6 h-6">
                      {<CheckIcon className="text-sm" />}
                    </span>
                  ) : undefined
                }
                className="font-bold text-xl"
              >
                {
                  <button
                    className="text-base font-bold font-fredoka"
                    type="button"
                    onClick={() => {
                      if (activeStep < index) {
                        return;
                      }
                      setActiveStep(index);
                    }}
                  >
                    {label}
                  </button>
                }
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === 0 && <TweetStep setActiveStep={setActiveStep} />}
        {activeStep === 1 && <VerifyStep />}
        {activeStep === 2 && <ClaimStep />}
        <CustomButton
          variant="contained"
          className="bg-primary-dark font-bold"
          onClick={() => {
            if (activeStep === 0) {
              setActiveStep(1);
            } else if (activeStep === 1) {
              setActiveStep(2);
            } else onClaimClick?.();
          }}
        >
          {claimLoading ? (
            <CircularProgress size={28} className="text-white" />
          ) : (
            <span> {handleButtonText()}</span>
          )}
        </CustomButton>
      </div>
    </Modal>
  );
}
