import { Dialog, Box, Typography } from '@mui/material';
import Slide from '@mui/material/Slide';
import { forwardRef } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { StyledPaper } from './styles';
import { pxToRem } from '../../../utils/getFontValue';

export function Modal({ open, onClose, children, title, ...props }) {
  return (
    <Dialog
      open={open}
      TransitionComponent={SlideUpTransition}
      keepMounted
      onClose={onClose}
      PaperComponent={StyledPaper}
      {...props}
    >
      {title && <Header onClose={onClose} title={title} />}
      {children}
    </Dialog>
  );
}

function Header({ onClose, title = '' }) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: pxToRem(24),
      }}
    >
      <Typography fontFamily="Fredoka One" variant="h6">
        {title}
      </Typography>
      <CloseIcon
        sx={{ color: ' #FF849A', cursor: 'pointer', zIndex: 2000 }}
        onClick={onClose}
      />
    </Box>
  );
}

export const SlideUpTransition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
