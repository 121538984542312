import { Box, styled } from '@mui/material';

export function SpanBox({ children, ...props }) {
  return (
    <Box component="span" {...props}>
      {children}
    </Box>
  );
}

export const Span = styled(SpanBox)({ display: 'inline' });
