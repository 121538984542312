import { Box, Divider, Typography } from '@mui/material';
import { FormattedNumber } from 'react-intl';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { SwapIcon, TransferIcon, WalletIcon } from '../../../../assets/svg';
import {
  BlackButton,
  BwjCoin,
  PrimaryLightButton,
  UsdtCoin,
  WusdCoin,
} from '../../../ui';
import { BalanceWrapper } from './BalanceWrapper';
import { formatBigNumber } from '../../../../utils';
import {
  useGetBwjBalance,
  useGetUsdtBalance,
  useGetWusdBalance,
} from '../../../../api/queries';
import { BUY_WOJ_LINK } from '../../../../utils/commonValues';
import { TransferModal } from './modal';
import { useBreakpointDown, useBreakpointUp } from '../../../../hooks';

export function TokenBalance(props) {
  const isUpLg = useBreakpointUp('lg');
  const isDownSm = useBreakpointDown('sm');
  const [openTransferModal, setOpenTransferModal] = useState(false);
  const { data: wusdBalance } = useGetWusdBalance();
  const { data: usdtBalance } = useGetUsdtBalance();
  const { data: babyWojBalance } = useGetBwjBalance();

  return (
    <>
      <BalanceWrapper color="primary.main" title="Your Balance" {...props}>
        <div className="lg:h-[calc(100%-8rem)]">
          <TokenBalanceSection
            tokenName="WUSD"
            tokenBalance={wusdBalance ? formatBigNumber(wusdBalance) : 0}
            tokenIcon={WusdCoin}
          />
          <Divider sx={{ my: '1rem' }} />
          <TokenBalanceSection
            tokenName="BWJ"
            tokenBalance={babyWojBalance ? formatBigNumber(babyWojBalance) : 0}
            tokenIcon={BwjCoin}
          />
          <Divider sx={{ my: '1rem' }} />
          <TokenBalanceSection
            tokenName="USDT"
            tokenBalance={usdtBalance ? formatBigNumber(usdtBalance, 18) : 0}
            tokenIcon={UsdtCoin}
          />
        </div>
        {isUpLg && (
          <BlackButton
            onClick={() => setOpenTransferModal(true)}
            fullWidth
            startIcon={<TransferIcon width="1.25rem" height="1.25rem" />}
            sx={{ mt: 3 }}
          >
            Transfer WUSD
          </BlackButton>
        )}
        <Box
          mt="2rem"
          display="flex"
          flexDirection={isDownSm ? 'column' : 'row'}
          justifyContent="flex-end"
          alignItems={isDownSm ? 'flex-start' : 'center'}
          gap={1}
        >
          {!isUpLg && (
            <BlackButton
              startIcon={<TransferIcon width="1.25rem" height="1.25rem" />}
              onClick={() => setOpenTransferModal(true)}
              fullWidth={isDownSm}
            >
              Transfer WUSD
            </BlackButton>
          )}
          <BlackButton
            LinkComponent="a"
            href={BUY_WOJ_LINK}
            target="_blank"
            variant="contained"
            startIcon={<WalletIcon width="1.25rem" height="1.25rem" />}
            sx={{ typography: 'subtitle2', whiteSpace: 'nowrap' }}
            fullWidth={isDownSm}
          >
            Buy Woj
          </BlackButton>
          <PrimaryLightButton
            sx={{ typography: 'subtitle2' }}
            component={Link}
            to="/swap"
            startIcon={<SwapIcon width="1.25rem" height="1.25rem" />}
            fullWidth={isDownSm}
          >
            Swap
          </PrimaryLightButton>
        </Box>
      </BalanceWrapper>
      <TransferModal
        wusdBalance={wusdBalance ? formatBigNumber(`${wusdBalance}`, 9) : 0}
        open={openTransferModal}
        handleClose={() => setOpenTransferModal(false)}
      />
    </>
  );
}

export function TokenBalanceSection({
  tokenName,
  tokenBalance,
  tokenIcon: TokenIcon,
  displayDigits = true,
}) {
  return (
    <Box display="flex" justifyContent="space-between">
      <Box>
        <Typography variant="caption" color="text.secondary">
          {tokenName}
        </Typography>
        <Typography variant="subtitle1">
          <FormattedNumber
            value={tokenBalance}
            minimumFractionDigits={displayDigits ? 2 : 0}
          />
        </Typography>
      </Box>
      <TokenIcon sx={{ alignSelf: 'end' }} />
    </Box>
  );
}
