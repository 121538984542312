import { useAccount, useContractRead } from 'wagmi';
import { bsc } from '@wagmi/chains';
import { USDT_ABI } from '../../../ABI';

export function useGetUsdtBalance() {
  const { address } = useAccount();

  return useContractRead({
    address: '0x55d398326f99059fF775485246999027B3197955',
    abi: USDT_ABI,
    functionName: 'balanceOf',
    args: [address],
    chainId: bsc.id,
    watch: true,
  });
}
