import { Box } from '@mui/material';
import { useAccount, useDisconnect } from 'wagmi';
import LoginIcon from '@mui/icons-material/Login';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import LogoutIcon from '@mui/icons-material/Logout';
// eslint-disable-next-line import/no-unresolved
import { ConnectKitButton } from 'connectkit';
import { LoginButton } from './styles';
import { walletAddress } from '../../../utils/commonValues';

export function SidebarLogin() {
  const { isConnected } = useAccount();
  const { disconnect } = useDisconnect();
  return (
    <Box
      sx={{
        px: 1,
        mt: {
          lg: 2,
          xl: 0,
        },
      }}
    >
      {isConnected ? (
        <LoginButton disableRipple onClick={() => disconnect()} variant="text">
          <LogoutIcon />
          <span>Disconnect</span>
        </LoginButton>
      ) : (
        <ConnectKitButton.Custom>
          {({ isConnected, show, address }) => (
            <LoginButton disableRipple onClick={show} variant="text">
              {isConnected ? (
                <AccountBoxIcon />
              ) : (
                <LoginIcon fontSize="small" />
              )}
              {!isConnected ? (
                <Box>Login</Box>
              ) : (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  {walletAddress(address)}
                </Box>
              )}
            </LoginButton>
          )}
        </ConnectKitButton.Custom>
      )}
    </Box>
  );
}
