import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useDisconnect } from '../../../hooks';
import { getUsersThunk } from '../../../redux/thunks/auth-thunk';

export function useUsers() {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { disconnect } = useDisconnect();
  function getUsers() {
    dispatch(getUsersThunk())
      .unwrap()
      .catch((err) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
        if (err.statusCode === 401) {
          disconnect();
        }
      });
  }

  return { getUsers };
}
