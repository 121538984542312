/* eslint-disable camelcase */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASE_URL as baseUrl, REDUCER_PATH as reducerPath } from './constants';
import * as Services from './lib';

export const clientNFTsApi = createApi({
  reducerPath,
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const { verifyToken } = getState().auth;
      if (verifyToken) {
        headers.set('authorization', `Bearer ${verifyToken}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getNFTs: Services.getNFTs({ builder }),
    getGenxNFTs: Services.getNFTs({ builder, isGenx: true }),
    getFreeMinNFTs: Services.getNFTs({ builder, isFreeMint: true }),

    getLevelUpPrice: Services.getLevelUpPrice({ builder }),
    levelUp: Services.levelUp({ builder }),
    verifyLevelUp: Services.verifyLevelUp({ builder }),

    getGenxLevelUpPrice: Services.getLevelUpPrice({ builder, isGenx: true }),
    levelUpGenx: Services.levelUp({ builder, isGenx: true }),
    verifyLevelUpGenx: Services.verifyLevelUp({ builder, isGenx: true }),

    getEnergyPrice: Services.getEnergyPrice({ builder }),
    energyUp: Services.energyUp({ builder }),
    verifyEnergyUp: Services.verifyEnergyUp({ builder }),

    getGenxEnergyPrice: Services.getEnergyPrice({ builder, isGenx: true }),
    energyUpGenx: Services.energyUp({ builder, isGenx: true }),
    verifyEnergyUpGenx: Services.verifyEnergyUp({ builder, isGenx: true }),

    getAllEnergyUpInquiry: Services.getAllEnergyUpInquiry({ builder }),
    allEnergyUpTransaction: Services.allEnergyUpTransaction({ builder }),
    verifyAllEnergyUpTransaction: Services.verifyAllEnergyUpTransaction({
      builder,
    }),

    getAllGenxEnergyUpInquiry: Services.getAllEnergyUpInquiry({
      builder,
      isGenx: true,
    }),
    allGenxEnergyUpTransaction: Services.allEnergyUpTransaction({
      builder,
      isGenx: true,
    }),
    verifyAllGenxEnergyUpTransaction: Services.verifyAllEnergyUpTransaction({
      builder,
      isGenx: true,
    }),

    setProfile: Services.setProfile({ builder }),
    removeProfile: Services.removeProfile({ builder }),
    verifyProfile: Services.verifyProfile({ builder }),

    setGenxProfile: Services.setProfile({ builder, isGenx: true }),
    removeGenxProfile: Services.removeProfile({ builder, isGenx: true }),
    verifyGenxProfile: Services.verifyProfile({ builder, isGenx: true }),

    setFreeMintProfile: Services.setProfile({ builder, isFreeMint: true }),
    removeFreeMintProfile: Services.removeProfile({
      builder,
      isFreeMint: true,
    }),
    verifyFreeMintProfile: Services.verifyProfile({
      builder,
      isFreeMint: true,
    }),

    addGroup: Services.addGroup({ builder }),
    deleteFromGroups: Services.deleteFromGroups({ builder }),

    addGenxGroup: Services.addGroup({ builder, isGenx: true }),
    deleteGenxFromGroups: Services.deleteFromGroups({ builder, isGenx: true }),

    getInviteCodes: Services.getInviteCodes({ builder }),

    activate: Services.activateUser({ builder }),

    inviteCodeTransactionPrice: Services.inviteCodeTransactionPrice({
      builder,
    }),
    inviteCodeTransaction: Services.inviteCodeTransaction({ builder }),
    verifyInviteCodeTransaction: Services.verifyInviteCodeTransaction({
      builder,
    }),

    getPermissionResult: Services.getPermissionResult({ builder }),
    activateSpecialReward: Services.activateSpecialReward({ builder }),

    getBlobs: Services.getBlobs({ builder }),
    getV3Blobs: Services.getV3Blobs({ builder }),

    getVirtualInviteCodes: Services.getVirtualInviteCodes({ builder }),

    // Unify
    specialRewards: Services.specialRewards({ builder }),

    generateCode: Services.generateCode({ builder }),

    claimRequest: Services.claimRequest({ builder }),

    getRewardIncome: Services.getRewardIncome({ builder }),

    getIncomes: Services.getIncomes({ builder }),

    buyBack: Services.buyBack({ builder }),

    getRewards: Services.getRewards({ builder }),
    claimReward: Services.claimReward({ builder }),

    getTasks: Services.getTasks({ builder }),
    checkTask: Services.checkTask({ builder }),

    changePlan: Services.changePlan({ builder }),

    getMintCount: Services.getMintCount({ builder }),

    transferWusd: Services.transferWusd({ builder }),
  }),
});

export const Api = clientNFTsApi;
