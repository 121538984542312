import { Box } from '@mui/material';
import { useBreakpointDown, useScaledDownMousePosition } from '../../hooks';

function usePosition(reversed, locked, threshold) {
  const isDownLg = useBreakpointDown('lg');
  const position = useScaledDownMousePosition(threshold);

  if (isDownLg || locked) return { x: 0, y: 0 };

  const x = reversed ? Math.abs(position.x) * -1 : position.x;
  const y = reversed ? Math.abs(position.y) * -1 : position.y;

  return { x, y };
}

export function MovingGradient({
  locked = false,
  reversed = false,
  lockX,
  lockY,
  wrapperSx = { zIndex: -10 },
  threshold,
  style,
  sx,
  ...props
}) {
  const { x, y } = usePosition(reversed, locked, threshold);
  const transform = `translateX(${lockX ? 0 : x}%) translateY(${
    lockY ? 0 : y
  }%) translate3d(0,0,0) translateZ(0)`;

  return (
    <Box
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        ...wrapperSx,
      }}
    >
      <Box sx={{ position: 'absolute', ...sx }} {...props}>
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
          }}
          style={{
            filter: 'blur(80px)',
            WebkitBackfaceVisibility: 'hidden',
            WebkitPerspective: 1000,
            WebkitTransform: 'translate3d(0, 0, 0) translateZ(0)',
            backfaceVisibility: 'hidden',
            perspective: 1000,
            borderRadius: '100%',
            background:
              'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, currentColor 100%)',
            transform,
            ...style,
          }}
        />
      </Box>
    </Box>
  );
}
