import { useState } from 'react';
import { Box, CircularProgress, FormControl, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import LockIcon from '@mui/icons-material/Lock';
import { useUpdateEffect } from 'react-haiku';
import { useDispatch, useSelector } from 'react-redux';
import { useAccount } from 'wagmi';
import {
  activeDialogOpen,
  closeActivationDialog,
  handleActive,
} from '../../../../redux/slices/authSlice';
import { CustomButton } from '../styles';
import Header from '../Header';
import { pxToRem } from '../../../../utils/getFontValue';
import { Api } from '../../../../api';
import { Modal } from '../../..';
import { parsedCookies } from '../../../../utils/cookies';
import { authThunk } from '../../../../redux/thunks/auth-thunk';

const ActivationDialog = () => {
  const [activate, { isLoading }] = Api.useActivateMutation();
  const { address } = useAccount();
  const isCookieAvailable = Boolean(parsedCookies && parsedCookies.token);
  const isActiveDialogOpen = useSelector(activeDialogOpen);
  const [activationCode, setActivationCode] = useState('');
  const [activationCodeError, setActivationCodeError] = useState(null);
  const [isBtnDisabled, setisBtnDisabled] = useState(false);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const validations = () => {
    if (activationCode.trim().length < 1) {
      setActivationCodeError('Required');
      setisBtnDisabled(true);
    }
  };

  const handleActivationCode = (e) => {
    setActivationCode(e.target.value);
  };

  const closeModalHandler = () => {
    dispatch(closeActivationDialog());
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (!isCookieAvailable && address) {
      dispatch(authThunk(address));
      return;
    }
    if (activationCode.trim().length < 1) {
      setActivationCodeError('Required');
      setisBtnDisabled(true);
      return;
    }

    try {
      const res = await activate({ code: activationCode });
      if (res.error) {
        enqueueSnackbar(res.error.data.message, {
          variant: 'error',
        });
      }

      if (res?.data?.status === true) {
        enqueueSnackbar('Your Account Is Active !', {
          variant: 'success',
        });
        dispatch(handleActive());
        closeModalHandler();
      }
    } catch (error) {
      enqueueSnackbar('Something Went Wrong', {
        variant: 'error',
      });
    }
  };

  useUpdateEffect(() => {
    validations();
    if (activationCode && activationCode.trim().length >= 1) {
      setActivationCodeError(null);
      setisBtnDisabled(false);
    }
  }, [activationCode]);

  return (
    <Modal
      open={isActiveDialogOpen}
      onClose={closeModalHandler}
      aria-describedby="activation-modal"
    >
      <Header handleClose={closeModalHandler} content="Activation" />
      <Box
        sx={{
          mb: 2,
          fontWeight: 700,
          display: 'flex',
          alignItems: 'center',
          columnGap: 1,
        }}
      >
        <span>Active Account</span>
        <LockIcon fontSize="small" />
      </Box>
      <Box
        onSubmit={submitHandler}
        component={'form'}
        sx={{
          px: {
            xs: 0,
            sm: pxToRem(64),
          },
        }}
      >
        <FormControl fullWidth sx={{ mt: '1rem' }}>
          <TextField
            autoComplete="off"
            value={activationCode}
            onChange={handleActivationCode}
            placeholder="Activation Code"
            error={!!activationCodeError}
            helperText={activationCodeError}
          />
        </FormControl>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: '1rem' }}>
          <CustomButton
            className="bg-primary-dark"
            disabled={isBtnDisabled}
            type="submit"
            variant="contained"
            sx={{ minHeight: 'auto' }}
          >
            {isLoading ? (
              <CircularProgress sx={{ color: '#fff' }} size={28} />
            ) : (
              'Active'
            )}
          </CustomButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default ActivationDialog;
