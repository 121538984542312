import { useContractRead } from 'wagmi';
import { bsc } from '@wagmi/chains';
import { PancakeSwapRouterV2, getBigNumber } from '../../../swap';
import {
  BWJ_CONTRACT_ADDRESS,
  BUSD_CONTRACT_ADDRESS,
  PANCAKESWAP_ROUTER_V2_ADDRESS,
} from './constants';

export function useGetBwjInUsd({ bwjAmount }) {
  return useContractRead({
    abi: PancakeSwapRouterV2,
    address: PANCAKESWAP_ROUTER_V2_ADDRESS,
    functionName: 'getAmountsOut',
    args: [
      getBigNumber(`${bwjAmount}`, 9),
      [BWJ_CONTRACT_ADDRESS, BUSD_CONTRACT_ADDRESS],
    ],
    watch: true,
    enabled: Boolean(bwjAmount),
    chainId: bsc.id,
  });
}
