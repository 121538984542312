import { Box } from '@mui/material';
import React from 'react';

const BoltIcon = ({ ...rest }) => (
  <Box {...rest}>
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.99528 11.3748C4.89528 11.3748 4.81528 11.3548 4.75528 11.3298C4.55528 11.2548 4.21528 11.0098 4.21528 10.2348V7.00983H3.04528C2.37528 7.00983 2.13528 6.69483 2.05028 6.50983C1.96528 6.31983 1.89028 5.93483 2.33028 5.42983L6.1153 1.12982C6.6253 0.54982 7.0403 0.58982 7.2403 0.66482C7.4403 0.73982 7.7803 0.98482 7.7803 1.75982V4.98482H8.9503C9.6203 4.98482 9.8603 5.29983 9.9453 5.48483C10.0303 5.67483 10.1053 6.05983 9.6653 6.56483L5.8803 10.8648C5.5253 11.2698 5.2153 11.3748 4.99528 11.3748ZM6.9653 1.36982C6.9503 1.38982 6.8453 1.43982 6.6803 1.62982L2.89528 5.92983C2.75528 6.08983 2.73528 6.18983 2.73528 6.20983C2.74528 6.21483 2.83528 6.26483 3.04528 6.26483H4.59028C4.79528 6.26483 4.96528 6.43483 4.96528 6.63983V10.2398C4.96528 10.4898 5.0103 10.5998 5.0303 10.6298C5.0453 10.6098 5.1503 10.5598 5.3153 10.3698L9.1003 6.06983C9.2403 5.90983 9.2603 5.80983 9.2603 5.78983C9.2503 5.78483 9.1603 5.73483 8.9503 5.73483H7.4053C7.2003 5.73483 7.0303 5.56483 7.0303 5.35983V1.75982C7.0353 1.50982 6.9853 1.40482 6.9653 1.36982Z"
        fill="white"
      />
    </svg>
  </Box>
);

export default BoltIcon;
