import { Button, styled } from '@mui/material';
import { pxToRem } from '../../../utils/getFontValue';

export const BlackButton = styled(Button)({
  // padding: '0 1rem',
  color: '#fff',
  background: '#000000 !important',
  ':hover': {
    background: '#000000 !important',
  },
  ':disabled': {
    background: '#14141B !important',
  },
});

export const PrimaryLightButton = styled(Button)(({ theme }) => ({
  // padding: '0 1rem',
  color: '#fff',
  background: `${theme.palette.primary.light} !important`,
  ':hover': {
    background: '#342D8A !important',
  },
  ':disabled': {
    background: '#26244C !important',
  },
}));

export const PrimaryDarkButton = styled(Button)(() => ({
  // padding: '0 1rem',
  color: '#fff',
  background: '#35375F !important',
  ':hover': {
    background: '#434678 !important',
  },
  ':disabled': {
    background: '#28293F !important',
  },
}));

export const ModalButton = styled(Button)(({ theme }) => ({
  background: theme.palette.primary.dark,
  minWidth: pxToRem(200),
  minHeight: pxToRem(40),
  [theme.breakpoints.up('sm')]: {
    minWidth: pxToRem(200),
    minHeight: pxToRem(46),
  },
  [theme.breakpoints.up('md')]: {
    minWidth: pxToRem(256),
    minHeight: pxToRem(56),
  },
}));
