/* eslint-disable camelcase */
export function getVirtualInviteCodes({ builder, isGenx = false }) {
  return builder.query({
    query: ({ user_id }) => ({
      url: isGenx
        ? '/user/genx/invite-codes/virtual'
        : `/user/invite-codes/virtual/${user_id}`,
    }),
  });
}
