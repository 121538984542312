import { LazyLoadImage } from 'react-lazy-load-image-component';
import { GENX_NFT } from '../../../../assets/img';

export function NftImage() {
  return (
    <div className="order-1 md:order-2 relative">
      <div className="absolute top-1/3 left-1/2 -translate-x-1/2 -translate-y-1/2 text-7xl text-blue-400 animate-bounce">
        ?
      </div>
      <LazyLoadImage
        src={GENX_NFT}
        alt="genx-nft"
        width={300}
        height={300}
        className="object-cover object-center rounded-xl blur-sm opacity-60 aspect-square"
      />
    </div>
  );
}
