import { styled } from '@mui/system';
import Slider from '@mui/material/Slider';
import { Button } from '@mui/material';
import { pxToRem } from '../../../utils/getFontValue';

/* Paper */

export const StyledPaper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'profileModal',
})(({ theme, profileModal }) => ({
  background: ' rgba(21, 24, 35, 0.4)',
  boxShadow:
    '0px 0px 196px rgba(44, 208, 149, 0.5), inset 0px -5.68965px 13.6552px rgba(9, 221, 183, 0.35), inset 0px 2.27586px 4.55172px rgba(255, 255, 255, 0.1) !important',
  backdropFilter: `blur(${pxToRem(40)})`,
  borderRadius: pxToRem(48),
  padding: '32px 36px',
  minWidth: `${pxToRem(300)}`,

  ...(!profileModal && {
    [theme.breakpoints.up('md')]: {
      minWidth: pxToRem(551),
      width: pxToRem(551),
    },
  }),

  ...(profileModal && {
    [theme.breakpoints.down('sm')]: {
      maxHeight: '88vh !important',
      marginBottom: 'auto !important',
    },
    [theme.breakpoints.up('sm')]: {
      minWidth: '90vw',
      maxWidth: '90vw',
    },
    [theme.breakpoints.up('md')]: {
      minWidth: pxToRem(704),
      maxWidth: pxToRem(704),
    },
  }),
}));

/* Custom Button */

export const CustomButton = styled(Button)(({ theme }) => ({
  minWidth: pxToRem(200),
  minHeight: pxToRem(40),
  [theme.breakpoints.up('sm')]: {
    minWidth: pxToRem(200),
    minHeight: pxToRem(46),
  },
  [theme.breakpoints.up('md')]: {
    minWidth: pxToRem(256),
    minHeight: pxToRem(56),
  },
}));

/* Image Wrapper */

export const ImgWrapper = styled('div')(({ theme }) => ({
  width: pxToRem(140),
  overflow: 'hidden',
  borderRadius: pxToRem(32),

  [theme.breakpoints.up('sm')]: {
    width: pxToRem(160),
  },
  [theme.breakpoints.up('md')]: {
    width: pxToRem(240),
  },
  [theme.breakpoints.up('lg')]: {
    width: pxToRem(278),
  },
}));

/* Slider Range */

export const CustomSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.primary.light,
  height: 10,
  '& .MuiSlider-rail': {
    background: 'rgba(168, 180, 247, 0.12)',
  },
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: theme.palette.primary.light,
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
}));
