import { useAccount, useContractRead } from 'wagmi';

export function useAllowance({
  contractAddress,
  abi,
  spenderAddress,
  onSuccess,
}) {
  const { address } = useAccount();

  return useContractRead({
    address: contractAddress,
    abi,
    functionName: 'allowance',
    args: [address, spenderAddress],
    enabled:
      Boolean(address) && Boolean(spenderAddress) && Boolean(spenderAddress),
    onSuccess(data) {
      onSuccess?.(data);
    },
    watch: true,
  });
}
