import { bsc, bscTestnet } from 'wagmi/chains';
import {
  FREE_MINT_TESTNET_ABI,
  FREE_MINT_MAINNET_ABI,
} from '../../../../../ABI';
import {
  FREE_MINT_TESTNET_ADDRESS,
  FREE_MINT_MAINNET_ADDRESS,
} from '../../constants';

export const freeMintTestnetConfig = {
  address: FREE_MINT_TESTNET_ADDRESS,
  abi: FREE_MINT_TESTNET_ABI,
  chainId: bscTestnet.id,
};

export const freeMintMainnetConfig = {
  address: FREE_MINT_MAINNET_ADDRESS,
  abi: FREE_MINT_MAINNET_ABI,
  chainId: bsc.id,
};
