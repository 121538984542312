import {
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from 'wagmi';
import { PancakeSwapRouterV2 } from '../abi';
import { addresses } from '../utils';

export const useSwapTokens = ({
  args,
  enabled,
  onSubmittedTransaction,
  onSuccessTransaction,
  onSettledTransaction,
}) => {
  const {
    config,
    error: prepareError,
    isError: isPrepareError,
  } = usePrepareContractWrite({
    address: addresses.pancakeSwapRouterV2,
    abi: PancakeSwapRouterV2,
    functionName: 'swapExactTokensForTokens',
    args,
    enabled,
  });

  const {
    data: swapData,
    isLoading: isLoadingWriteContract,
    isSuccess: isSuccessWriteContract,
    write,
  } = useContractWrite({
    ...config,
    onSuccess() {
      onSubmittedTransaction?.();
    },
  });

  const {
    isLoading: isLoadingWaitForTransaction,
    data: transactionReceipt,
    isSuccess: isSuccessTransaction,
    isError: isErrorTransaction,
    error: errorTransaction,
  } = useWaitForTransaction({
    hash: swapData?.hash,

    onSuccess(data) {
      onSuccessTransaction?.(data);
    },
    onSettled(data, error) {
      onSettledTransaction?.(data, error);
    },
  });

  return {
    hash: swapData?.hash,
    prepareError,
    isLoadingWriteContract,
    isSuccessWriteContract,
    write,
    isLoadingWaitForTransaction,
    transactionReceipt,
    isSuccessTransaction,
    isPrepareError,
    swapData,
    isErrorTransaction,
    errorTransaction,
  };
};
