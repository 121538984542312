export function allEnergyUpTransaction({ builder, isGenx = false }) {
  return builder.mutation({
    query: ({ currency }) => ({
      url: isGenx ? '/genx/energy-up/all' : '/transaction/energy-up/all',
      method: 'POST',
      params: {
        currency,
      },
    }),
  });
}
