import {
  useAccount,
  useContractRead,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from 'wagmi';
import { formatUnits, parseUnits } from 'ethers/lib/utils';
import { freeMintTestnetConfig, freeMintMainnetConfig } from './constants';
import { ENABLE_TESTNETS } from '../../../../constants';

const freeMintConfig =
  ENABLE_TESTNETS === 'true' ? freeMintTestnetConfig : freeMintMainnetConfig;

export function useWalletOfOwner() {
  const { address } = useAccount();

  const { data, isLoading } = useContractRead({
    ...freeMintConfig,
    functionName: 'walletOfOwner',
    args: [address],
    enabled: Boolean(address),
    watch: true,
  });

  return {
    nfts: data ? data.map((nft) => Number(formatUnits(nft, 0))) : [],
    isLoading,
  };
}

export function useTokenURI({ tokenId }) {
  const { data, isLoading } = useContractRead({
    ...freeMintConfig,
    functionName: 'tokenURI',
    args: [parseUnits(String(tokenId ?? 0), 0)],
    enabled: Boolean(tokenId),
    watch: true,
  });

  return {
    tokenURI: data,
    isLoading,
  };
}

export function useClaim({
  onSubmittedTransaction,
  onSuccessTransaction,
  onSettledTransaction,
  onErrorSubmittedTransaction,
  onErrorTransaction,
  isClaimingMale = false,
}) {
  const {
    config,
    error: prepareError,
    isError: isPrepareError,
  } = usePrepareContractWrite({
    ...freeMintConfig,
    functionName: 'claim',
    args: [isClaimingMale ? 0 : 1],
  });

  const {
    data: claimData,
    isLoading: isLoadingWriteContract,
    isSuccess: isSuccessWriteContract,
    write,
  } = useContractWrite({
    ...config,
    onSuccess() {
      onSubmittedTransaction?.();
    },
    onError(error) {
      onErrorSubmittedTransaction?.(error);
    },
  });

  const {
    isLoading: isLoadingWaitForTransaction,
    data: transactionReceipt,
    isSuccess: isSuccessTransaction,
    isError: isErrorTransaction,
    error: errorTransaction,
  } = useWaitForTransaction({
    hash: claimData?.hash,

    onSuccess(data) {
      onSuccessTransaction?.(data);
    },
    onSettled() {
      onSettledTransaction?.();
    },
    onError(error) {
      onErrorTransaction?.(error);
    },
  });

  return {
    hash: claimData?.hash,
    prepareError,
    isLoadingWriteContract,
    isSuccessWriteContract,
    claim: write,
    isLoadingWaitForTransaction,
    transactionReceipt,
    isSuccessTransaction,
    isPrepareError,
    claimData,
    isErrorTransaction,
    errorTransaction,
  };
}
