/* eslint-disable import/no-unresolved */
import { ConnectKitProvider } from 'connectkit';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import ThemeProvider from './theme';
import { MotionLazyContainer } from './components/animate';
import Router from './routes';
import { store } from './redux/store';
import WagmiProvider from './components/wagmi/WagmiProvider';
import AuthProvider from './components/auth-provider/AuthProvider';
import ActivationDialog from './components/games/modals/activation/ActivationDialog';
import 'swiper/css';
import 'swiper/css/pagination';
import { SnackbarProvider } from './components';

function App() {
  return (
    <WagmiProvider>
      <IntlProvider locale="en" defaultLocale="en">
        <Provider store={store}>
          <SnackbarProvider>
            <MotionLazyContainer>
              <ThemeProvider>
                <AuthProvider>
                  <ConnectKitProvider
                    theme="midnight"
                    customTheme={{
                      '--ck-body-background': '#171353',
                      '--ck-body-background-secondary': '#000000',
                      '--ck-primary-button-background': '#000000',
                      '--ck-secondary-button-background': '#000000',
                      '--ck-body-background-tertiary': '#262089',
                    }}
                  >
                    <Router />
                    <ActivationDialog />
                  </ConnectKitProvider>
                </AuthProvider>
              </ThemeProvider>
            </MotionLazyContainer>
          </SnackbarProvider>
        </Provider>
      </IntlProvider>
    </WagmiProvider>
  );
}

export default App;
