// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField } from '@mui/material';
import { NumericFormat } from 'react-number-format';

// ----------------------------------------------------------------------

export default function RHFNumericTextField({ name, helperText, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <NumericFormat
          {...field}
          decimalScale={0}
          customInput={TextField}
          fullWidth
          InputProps={{
            sx: {
              inputMode: 'numeric',
              pattern: '[0-9]*',
            },
          }}
          value={
            typeof field.value === 'number' && field.value === 0
              ? ''
              : field.value
          }
          error={!!error}
          helperText={error ? error?.message : helperText}
          {...other}
        />
      )}
    />
  );
}
