import { useAccount, useContractRead } from 'wagmi';

export function useGetMinReward({ address, abi }) {
  const { isConnected } = useAccount();
  return useContractRead({
    address,
    abi,
    functionName: 'minReward',
    enabled: isConnected,
  });
}
