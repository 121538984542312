/* eslint-disable camelcase */
export function inviteCodeTransaction({ builder, isGenx = false }) {
  return builder.mutation({
    query: ({ token_address, token_id, package_id }) => ({
      url: isGenx
        ? '/transaction/genx/invite-code'
        : '/transaction/invite-code',
      method: 'POST',
      body: {
        token_address,
        token_id,
        package_id,
      },
    }),
  });
}
