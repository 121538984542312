/* eslint-disable camelcase */
import queryString from 'query-string';

export function repost({ extraId, tweetMessage }) {
  const baseUrl = 'https://x.com/intent/tweet';
  const query = {
    url: `https://x.com/user/status/${extraId}`,
    text: tweetMessage || '',
  };
  return queryString.stringifyUrl({ url: baseUrl, query });
}
