import { Banner } from './Banner';
import { Tasks } from './Tasks';
import { AuthGuard } from '../auth-guard';

export function EventsPage() {
  return (
    <AuthGuard>
      <div className="flex flex-col gap-y-12">
        <Banner />
        <Tasks />
      </div>
    </AuthGuard>
  );
}
