import { Button, Slider, styled } from '@mui/material';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import { pxToRem } from '../../utils/getFontValue';

export const CustomButton = styled(Button)(({ theme }) => ({
  minWidth: pxToRem(200),
  minHeight: pxToRem(40),
  [theme.breakpoints.up('sm')]: {
    minWidth: pxToRem(200),
    minHeight: pxToRem(46),
  },
  [theme.breakpoints.up('md')]: {
    minWidth: pxToRem(256),
    minHeight: pxToRem(56),
  },
}));

export const StyledLoginBtn = styled(Button)(({ theme }) => ({
  background: theme.palette.secondary.glass,
  display: 'flex',
  alignItems: 'center',
  columnGap: theme.spacing(1),
  padding: `${theme.spacing(1.5)} ${theme.spacing(3)}`,
  borderRadius: theme.spacing(3),
  fontWeight: 700,

  ':hover': {
    background: theme.palette.secondary.glass,
  },

  [theme.breakpoints.up('md')]: {
    minWidth: pxToRem(150),
  },
}));

export const BorderLinearProgress = styled(LinearProgress, {
  // shouldForwardProp: (prop) => prop !== "bgColor",
})(({ theme }) => ({
  height: 8,
  borderRadius: 5,
  zIndex: 100,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[900],
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 5,
  },
}));

export const CustomSlider = styled(Slider)(({ theme }) => ({
  color: '#52af77',
  height: 10,
  '& .MuiSlider-rail': {
    background: 'rgba(168, 180, 247, 0.12)',
  },
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    backgroundColor: theme.palette.primary.main,
  },
}));

export const StyledClaimBtn = styled(Button)`
  min-width: 250px;
  height: 50px;
  border: none;
  outline: none;
  color: #fff;
  background-color: #212435;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  padding: 0 2rem;
  box-shadow: none;

  ::before {
    content: '';
    background: linear-gradient(
      45deg,
      #ff0000,
      #ff7300,
      #fffb00,
      #48ff00,
      #00ffd5,
      #002bff,
      #7a00ff,
      #ff00c8,
      #ff0000
    );
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
    border-radius: 10px;
  }

  :active {
    color: #000;
    font-weight: 700;
  }
  :active:after {
    background: transparent;
  }

  :after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #212435;
    left: 0;
    top: 0;
    border-radius: 10px;
  }

  @keyframes glowing {
    0% {
      background-position: 0 0;
    }
    50% {
      background-position: 400% 0;
    }
    100% {
      background-position: 0 0;
    }
  }
`;
