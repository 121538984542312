import {
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from 'wagmi';
import { MINT_CONTRACT_ADDRESS as address } from './address';
import { MINT_ABI as abi } from '../../../../ABI';
import { getBigNumber } from '../../../../utils';

export const useMintRequest = ({
  onSubmittedTransaction,
  onSuccessTransaction,
  onSettledTransaction,
  onErrorSubmittedTransaction,
  onErrorTransaction,
  args,
}) => {
  const {
    config,
    error: prepareError,
    isError: isPrepareError,
  } = usePrepareContractWrite({
    address,
    abi,
    functionName: 'mintReqOptional',
    args,
    enabled: args?.length === 3,
    overrides: { gasPrice: getBigNumber('4', 'gwei'), gasLimit: 550000 },
  });

  const {
    data: mintData,
    isLoading: isLoadingWriteContract,
    isSuccess: isSuccessWriteContract,
    write,
  } = useContractWrite({
    ...config,
    onSuccess() {
      onSubmittedTransaction?.();
    },
    onError(error) {
      onErrorSubmittedTransaction?.(error);
    },
  });

  const {
    isLoading: isLoadingWaitForTransaction,
    data: transactionReceipt,
    isSuccess: isSuccessTransaction,
    isError: isErrorTransaction,
    error: errorTransaction,
  } = useWaitForTransaction({
    hash: mintData?.hash,

    onSuccess(data) {
      onSuccessTransaction?.(data);
    },
    onSettled() {
      onSettledTransaction?.();
    },
    onError(error) {
      onErrorTransaction?.(error);
    },
  });

  return {
    hash: mintData?.hash,
    prepareError,
    isLoadingWriteContract,
    isSuccessWriteContract,
    write,
    isLoadingWaitForTransaction,
    transactionReceipt,
    isSuccessTransaction,
    isPrepareError,
    mintData,
    isErrorTransaction,
    errorTransaction,
  };
};
