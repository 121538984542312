import { Avatar, Skeleton, Typography } from '@mui/material';
import clsx from 'clsx';

export function Status({ tasksQuery, twitterNft }) {
  const { data } = tasksQuery;

  return (
    <div className="w-full lg:w-[30%]">
      <Typography mb="1.5rem" variant="h5">
        Your Status
      </Typography>

      <div>
        <div className="p-4 w-full flex items-center gap-x-2 lg:gap-x-4 bg-black rounded-xl">
          <Avatar
            src={twitterNft()}
            className="w-9 h-9 md:w-12 md:h-12 rounded-md"
          />
          <div className="flex flex-col">
            {data ? (
              <p className="font-bold">
                {data.total} Task{data.total > 1 ? 's' : ''}
              </p>
            ) : (
              <Skeleton variant="text" />
            )}
            <p className="opacity-50 text-xs ">total tasks</p>
          </div>
        </div>
        <div className="my-8 flex items-center justify-between gap-4 :gap-8">
          <TasksBox
            variant="Done"
            data={data}
            skeletonClassName="bg-primary-dark"
          />
          <TasksBox data={data} skeletonClassName="bg-primary" />
        </div>
        <div>
          <Typography mt="1rem" variant="body2">
            Complete your tasks in order to claim{' '}
            <span className="underline">rewards</span>
          </Typography>
        </div>
      </div>
    </div>
  );
}

function TasksBox({
  variant = 'done',
  data,
  className,
  skeletonClassName,
  ...props
}) {
  if (!data) {
    return <TasksBoxSkeleton className={skeletonClassName} />;
  }

  return (
    <div
      className={clsx(
        'p-4 flex flex-col justify-between min-h-[6.5rem] rounded-xl w-full',
        variant === 'done' ? 'bg-primary' : 'bg-primary-dark',
        className
      )}
      {...props}
    >
      <div className="flex flex-col gap-y-1">
        <div className="flex items-center gap-x-2">
          <h6 className="text-lg font-bold">{`${
            variant === 'done' ? data.totalDone : data.totalNotDone
          } Task${
            (variant === 'done' && data.totalDone > 1 ? 's' : '') ||
            (variant !== 'done' && data.totalNotDone > 1 ? 's' : '')
          }`}</h6>
        </div>
        <p className="opacity-50 text-xs">
          {variant === 'done' ? 'Finished Successfully' : 'Remained in Queue'}
        </p>
      </div>
    </div>
  );
}

function TasksBoxSkeleton({ className, ...props }) {
  return (
    <div
      className={clsx(
        'p-4 flex flex-col justify-between min-h-[6.5rem] rounded-xl w-full',
        className
      )}
      {...props}
    >
      <div className="flex flex-col gap-y-1">
        <div className="flex items-center gap-x-2">
          <Skeleton variant="text" className="w-16" />
        </div>
        <Skeleton variant="text" className="opacity-50 text-xs w-10" />
      </div>
    </div>
  );
}
