import {
  useAccount,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from 'wagmi';

export const useConvertToWusd = ({
  address,
  abi,
  onSubmittedTransaction,
  onSuccessTransaction,
  onSettledTransaction,
  onErrorSubmittedTransaction,
  onErrorTransaction,
}) => {
  const { address: walletAddress } = useAccount();

  const {
    config,
    error: prepareError,
    isError: isPrepareError,
  } = usePrepareContractWrite({
    address,
    abi,
    functionName: 'convertToWUSD',
    enabled: Boolean(walletAddress),
  });

  const {
    data: convertToWusdData,
    isLoading: isLoadingWriteContract,
    isSuccess: isSuccessWriteContract,
    write,
  } = useContractWrite({
    ...config,
    onSuccess() {
      onSubmittedTransaction?.();
    },
    onError(error) {
      onErrorSubmittedTransaction?.(error);
    },
  });

  const {
    isLoading: isLoadingWaitForTransaction,
    data: transactionReceipt,
    isSuccess: isSuccessTransaction,
    isError: isErrorTransaction,
    error: errorTransaction,
  } = useWaitForTransaction({
    hash: convertToWusdData?.hash,

    onSuccess(data) {
      onSuccessTransaction?.(data);
    },
    onSettled() {
      onSettledTransaction?.();
    },
    onError(error) {
      onErrorTransaction?.(error);
    },
  });

  return {
    hash: convertToWusdData?.hash,
    prepareError,
    isLoadingWriteContract,
    isSuccessWriteContract,
    write,
    isLoadingWaitForTransaction,
    transactionReceipt,
    isSuccessTransaction,
    isPrepareError,
    convertToWusdData,
    isErrorTransaction,
    errorTransaction,
  };
};
