import { useGetTasks } from '../../../api/queries/getTasks';
import { Status } from './Status';
import { ActiveTasks } from './ActiveTasks';
import { useGetNFTs } from '../../../api/queries/getNfts';

export function Tasks() {
  const { data: nfts } = useGetNFTs();

  const twitterNft = () => {
    if (nfts)
      return nfts?.data?.find((nft) => nft?.isTwitterProfile)?.[
        'image' || 'image2'
      ];
    return null;
  };
  const tasksQuery = useGetTasks();

  return (
    <div className="flex flex-col lg:flex-row gap-4">
      <ActiveTasks tasksQuery={tasksQuery} twitterNft={() => twitterNft()} />
      <Status tasksQuery={tasksQuery} twitterNft={() => twitterNft()} />
    </div>
  );
}
