/* eslint-disable jsx-a11y/alt-text */
import { Avatar } from '@mui/material';
import React, { useState } from 'react';

export function ImageWithFallback({ fallbackSrc, src, ...props }) {
  const [imgSrc, setImgSrc] = useState(src);
  const onError = () => setImgSrc(fallbackSrc);

  return (
    <Avatar
      src={imgSrc || fallbackSrc}
      onError={onError}
      loading="lazy"
      {...props}
    />
  );
}
