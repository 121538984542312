export const PANCAKESWAP_ROUTER_V2_ADDRESS =
  '0x10ED43C718714eb63d5aA57B78B54704E256024E';

export const BWJ_CONTRACT_ADDRESS =
  '0x83F41c98d028842Ccc8060b4Ec7738Df3eb9a2e6';

export const BUSD_CONTRACT_ADDRESS =
  '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56';

export const FREE_MINT_TESTNET_ADDRESS =
  '0x3186a7f366E1dF068A47C006a12aF465fd6A72Ed';

export const FREE_MINT_MAINNET_ADDRESS =
  '0x578B30D68CF94E6D64074F2b890405FfAd6862b1';
