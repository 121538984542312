export function getNFTs({ builder, isGenx = false, isFreeMint = false }) {
  return builder.query({
    query: ({ limit, cursor }) => ({
      url: isGenx
        ? '/genx/nfts'
        : isFreeMint
        ? '/free-mint/nfts'
        : '/web3/v2/nfts',
      params: {
        limit,
        cursor,
      },
    }),
  });
}
