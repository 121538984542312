import { Box, Typography } from '@mui/material';
import { pxToRem } from '../../../../../utils/getFontValue';
import { useBreakpointUp } from '../../../../../hooks';

export function VerifyStep() {
  const isUpSm = useBreakpointUp('sm');

  return (
    <div className="flex flex-col gap-y-3">
      <p className="font-bold tracking-wide">Pending Tweet ...</p>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          background: 'rgba(16, 16, 31, 0.64)',
          borderRadius: pxToRem(16),
          border: '2px solid',
          borderColor: 'text.secondary',
          py: pxToRem(24),
          mt: '1rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            columnGap: isUpSm ? pxToRem(32) : pxToRem(16),
            px: 2,
          }}
        >
          {/* <TwitterProgress /> */}
          <Typography>
            Wojakians is waiting for you to complete your tasks. Thereupon,
            press verify bottom for verification.
          </Typography>
        </Box>
      </Box>
    </div>
  );
}
