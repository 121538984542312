/* eslint-disable camelcase */
export function verifyInviteCodeTransaction({ builder, isGenx = false }) {
  return builder.mutation({
    query: ({ tx_hash, data_hash }) => ({
      url: isGenx
        ? '/transaction/genx/invite-code/verify'
        : '/transaction/invite-code/verify',
      method: 'POST',
      body: { tx_hash, data_hash },
    }),
  });
}
