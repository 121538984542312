export function claimReward({ builder, isGenx = false }) {
  return builder.mutation({
    query: ({ type, currency }) => ({
      url: isGenx
        ? `/user/genx/rewards/${type}/claim/${currency}`
        : `/user/rewards/${type}/claim/${currency}`,
      method: 'POST',
    }),
  });
}
