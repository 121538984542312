import { m } from 'framer-motion';
// @mui
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
//
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Logo from '../../assets/img/logo 1.png';
import ProgressBar from '../progress-bar';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  right: 0,
  bottom: 0,
  zIndex: 9998,
  width: '100%',
  height: '100%',
  position: 'fixed',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.grey[900],
}));

// ----------------------------------------------------------------------

export default function LoadingScreen() {
  return (
    <>
      <ProgressBar />

      <StyledRoot>
        <m.div
          animate={{
            y: [0, -20, 0],
          }}
          transition={{
            duration: 3,
            ease: 'linear',
            repeat: Infinity,
          }}
        >
          <Box
            component={LazyLoadImage}
            src={Logo}
            alt="logo"
            sx={{
              width: 150,
              height: 150,
              background: '#fff',
              borderRadius: '50%',
            }}
            visibleByDefault
          />
        </m.div>
      </StyledRoot>
    </>
  );
}
