import { Avatar } from '@mui/material';
import React from 'react';
import { BabyWojCoinIcon } from '../../../assets/svg';

export function BwjCoin({ width = '1.5rem', height = '1.5rem', sx }) {
  return (
    <Avatar
      src={BabyWojCoinIcon}
      sx={{ width, height, ...sx }}
      alt="Baby woj coin"
    />
  );
}
