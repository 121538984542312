import { useAccount } from 'wagmi';
import { useSnackbar } from 'notistack';
import { CircularProgress } from '@mui/material';
import { CustomButton } from '../../../games/modals/styles';
import { PrimaryLightButton } from '../../../ui';

export function ApproveButtons({ usdtApprove, wusdApprove, mintAmount }) {
  const { enqueueSnackbar } = useSnackbar();
  const isConnected = useAccount();

  const {
    isAllowedToPay: isUsdtAllowedToPay,
    prepare: prepareUsdt,
    write: approveUsdt,
    transaction: transactionUsdt,
  } = usdtApprove;
  const {
    isAllowedToPay: isWusdAllowedToPay,
    prepare: prepareWusd,
    write: approveWusd,
    transaction: transactionWusd,
  } = wusdApprove;

  const isUsdtLoading =
    prepareUsdt.isLoading || approveUsdt.isLoading || transactionUsdt.isLoading;
  const isWusdLoading =
    prepareWusd.isLoading || approveWusd.isLoading || transactionWusd.isLoading;

  return (
    <div className="mb-2 flex flex-col gap-y-2">
      {isConnected && mintAmount > 0 && !isUsdtAllowedToPay && (
        <PrimaryLightButton
          className="mb-2"
          disabled={isUsdtLoading}
          onClick={() => {
            if (prepareUsdt.error) {
              enqueueSnackbar(prepareUsdt.error.reason, {
                variant: 'warning',
              });
              return;
            }
            if (approveUsdt.write) {
              approveUsdt.write();
            }
          }}
        >
          {isUsdtLoading ? (
            <CircularProgress className="text-white" size={25} />
          ) : (
            ' Approve USDT'
          )}
        </PrimaryLightButton>
      )}
      {isConnected && mintAmount > 0 && !isWusdAllowedToPay && (
        <PrimaryLightButton
          className="mb-2"
          disabled={isWusdLoading}
          onClick={() => {
            if (prepareWusd.error) {
              enqueueSnackbar(prepareWusd.error.reason, {
                variant: 'warning',
              });
              return;
            }
            if (approveWusd.write) {
              approveWusd.write();
            }
          }}
        >
          {isWusdLoading ? (
            <CircularProgress className="text-white" size={25} />
          ) : (
            ' Approve WUSD'
          )}
        </PrimaryLightButton>
      )}
    </div>
  );
}
