export function EventsIcon({ color = '#fff', ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g opacity="0.9">
        <path
          d="M12.1504 19.35C11.7404 19.35 11.4004 19.01 11.4004 18.6V16.5C11.4004 16.09 11.7404 15.75 12.1504 15.75C12.5604 15.75 12.9004 16.09 12.9004 16.5V18.6C12.9004 19.01 12.5604 19.35 12.1504 19.35Z"
          fill={color}
        />
        <path
          d="M17.9004 22.75H6.40039V21C6.40039 19.48 7.63039 18.25 9.15039 18.25H15.1504C16.6704 18.25 17.9004 19.48 17.9004 21V22.75ZM7.90039 21.25H16.4004V21C16.4004 20.31 15.8404 19.75 15.1504 19.75H9.15039C8.46039 19.75 7.90039 20.31 7.90039 21V21.25Z"
          fill={color}
        />
        <path
          d="M18.1504 22.75H6.15039C5.74039 22.75 5.40039 22.41 5.40039 22C5.40039 21.59 5.74039 21.25 6.15039 21.25H18.1504C18.5604 21.25 18.9004 21.59 18.9004 22C18.9004 22.41 18.5604 22.75 18.1504 22.75Z"
          fill={color}
        />
        <path
          d="M18.4307 12.4406C18.2207 12.4406 18.0107 12.3506 17.8607 12.1806C17.6707 11.9606 17.6207 11.6506 17.7407 11.3906C18.0807 10.6106 18.2507 9.78055 18.2507 8.91055V5.91055C18.2507 5.56055 18.1907 5.22055 18.0707 4.86055C18.0607 4.83055 18.0507 4.79055 18.0407 4.75055C18.0107 4.60055 18.0007 4.45055 18.0007 4.31055C18.0007 3.90055 18.3407 3.56055 18.7507 3.56055H19.3507C21.1407 3.56055 22.6007 5.06055 22.6007 6.91055C22.6007 8.44055 21.9707 9.95055 20.8807 11.0406C20.8607 11.0606 20.8007 11.1106 20.7907 11.1206C20.2007 11.6106 19.5307 12.1606 18.6307 12.4106C18.5607 12.4306 18.5007 12.4406 18.4307 12.4406ZM19.6807 5.09055C19.7307 5.36055 19.7507 5.64055 19.7507 5.91055V8.91055C19.7507 9.32055 19.7207 9.71055 19.6607 10.1106C19.7207 10.0606 19.7707 10.0206 19.8307 9.97055C20.6307 9.17055 21.1007 8.05055 21.1007 6.91055C21.1007 6.01055 20.4907 5.25055 19.6807 5.09055Z"
          fill={color}
        />
        <path
          d="M5.58039 12.3996C5.50039 12.3996 5.43039 12.3896 5.35039 12.3596C4.53039 12.0996 3.76039 11.6196 3.12039 10.9796C1.97039 9.70961 1.40039 8.31961 1.40039 6.84961C1.40039 5.02961 2.83039 3.59961 4.65039 3.59961H5.30039C5.55039 3.59961 5.79039 3.72961 5.93039 3.93961C6.07039 4.14961 6.09039 4.41961 5.99039 4.64961C5.83039 5.00961 5.75039 5.41961 5.75039 5.84961V8.84961C5.75039 9.70961 5.92039 10.5496 6.27039 11.3496C6.39039 11.6196 6.33039 11.9296 6.14039 12.1496C5.99039 12.3096 5.79039 12.3996 5.58039 12.3996ZM4.30039 5.12961C3.49039 5.28961 2.90039 5.98961 2.90039 6.84961C2.90039 7.93961 3.34039 8.98961 4.21039 9.94961C4.25039 9.99961 4.30039 10.0396 4.35039 10.0796C4.28039 9.66961 4.25039 9.25961 4.25039 8.84961V5.84961C4.25039 5.60961 4.27039 5.36961 4.30039 5.12961Z"
          fill={color}
        />
        <path
          d="M12 16.75C7.73 16.75 4.25 13.27 4.25 9V6C4.25 3.38 6.38 1.25 9 1.25H15C17.62 1.25 19.75 3.38 19.75 6V9C19.75 13.27 16.27 16.75 12 16.75ZM9 2.75C7.21 2.75 5.75 4.21 5.75 6V9C5.75 12.45 8.55 15.25 12 15.25C15.45 15.25 18.25 12.45 18.25 9V6C18.25 4.21 16.79 2.75 15 2.75H9Z"
          fill={color}
        />
      </g>
    </svg>
  );
}
