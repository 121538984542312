import React from 'react';
import { Box, Dialog } from '@mui/material';
import { SlideUpTransition } from '../SlideUpTransition';
import { StyledPaper } from '../styles';
import ProgressWithLabel from './ProgressWithLabel';

const LoadingModal = ({ open, handleClose, content }) => (
  <Dialog
    open={open}
    TransitionComponent={SlideUpTransition}
    keepMounted
    onClose={handleClose}
    aria-describedby={`loading-${content}`}
    PaperComponent={StyledPaper}
    PaperProps={{
      sx: {
        minHeight: '70vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    }}
  >
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <ProgressWithLabel size={100} content={content} />
    </Box>
  </Dialog>
);

export default LoadingModal;
