/* eslint-disable camelcase */
export function verifyEnergyUp({ builder, isGenx = false }) {
  return builder.mutation({
    query: ({ tx_hash, data_hash }) => ({
      url: isGenx ? '/genx/energy-up/verify' : '/transaction/energy-up/verify',
      method: 'POST',
      body: {
        tx_hash,
        data_hash,
      },
    }),
  });
}
