import { useAccount, useContractRead } from 'wagmi';
import { bsc } from '@wagmi/chains';
import WojakABI from '../../../ABI/wojak-abi.json';

export function useGetWojBalance() {
  const { address } = useAccount();

  return useContractRead({
    address: '0x55F96C7005D7C684A65Ee653B07b5FE1507C56ab',
    abi: WojakABI,
    functionName: 'balanceOf',
    args: [address],
    chainId: bsc.id,
    watch: true,
  });
}
