import React from 'react';
import { Box } from '@mui/material';
import { Modal } from '..';
import ProgressWithLabel from '../games/modals/loading/ProgressWithLabel';

export function LoadingModal({ open, handleClose, content }) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-describedby={`loading-${content}`}
      PaperProps={{
        sx: {
          minHeight: 260,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <ProgressWithLabel size={60} content={content} />
      </Box>
    </Modal>
  );
}
