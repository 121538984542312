/* eslint-disable camelcase */
export function generateCode({ builder, isGenx = false }) {
  return builder.mutation({
    query: ({ child_id }) => ({
      url: isGenx ? '/user/genx/generate-code' : '/user/generate-code',
      method: 'POST',
      body: { child_id },
    }),
  });
}
