import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAccount } from 'wagmi';
import {
  authLoading,
  clearUsersError,
  handleActive,
  handleVerificationToken,
  selectCurrentNonce,
  selectCurrentToken,
  selectCurrentVerifyToken,
  selectGetUsersError,
  selectNotifications,
  setClientAddress,
} from '../../redux/slices/authSlice';
import { authThunk, notificationThunk } from '../../redux/thunks/auth-thunk';
import { parsedCookies } from '../../utils/cookies';
import NotificationsDialog from '../games/modals/notifications/NotificationsDialog';
import { AuthModal } from './AuthModal';
import { useNotifications, useSignMessage, useUsers } from './hooks';
import { useDisconnect } from '../../hooks';
import { FLAGS } from '../../constants';

const AuthProvider = ({ children }) => {
  const [openAuthModal, setOpenAuthModal] = useState(false);
  const notifications = useSelector(selectNotifications);
  const getUsersError = useSelector(selectGetUsersError);
  const isAuthLoading = useSelector(authLoading);
  const token = useSelector(selectCurrentToken);
  const verifyToken = useSelector(selectCurrentVerifyToken);
  const nons = useSelector(selectCurrentNonce);
  const { address, isConnected } = useAccount();
  const { disconnect } = useDisconnect();
  const dispatch = useDispatch();
  const [callSign, setCallSign] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(true);
  const { signMessage, isLoading: isSignLoading } = useSignMessage({
    onSettled: () => setOpenAuthModal(false),
  });

  const { notificationModalOpen, setNotificationModalOpen } =
    useNotifications();

  const { getUsers } = useUsers();

  useEffect(() => {
    if (FLAGS.IS_UNDER_MAINTENANCE) {
      return;
    }
    if (isConnected) {
      dispatch(notificationThunk());
    } else {
      disconnect();
      setIsAuthorized(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected]);

  useEffect(() => {
    if (FLAGS.IS_UNDER_MAINTENANCE) {
      return;
    }
    if (isConnected && address) {
      // removeCookie('verification')
      dispatch(setClientAddress(address));
      if (parsedCookies && parsedCookies?.address === address && isAuthorized) {
        dispatch(handleVerificationToken(parsedCookies?.token));
        if (parsedCookies?.active) {
          dispatch(handleActive());
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected, address]);

  useEffect(() => {
    if (FLAGS.IS_UNDER_MAINTENANCE) {
      return;
    }
    if (isConnected && token && nons && !verifyToken) {
      setCallSign(true);
    } else {
      setCallSign(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected, token, verifyToken]);

  useEffect(() => {
    if (FLAGS.IS_UNDER_MAINTENANCE) {
      return;
    }
    if (callSign) {
      setOpenAuthModal(true);
    }
  }, [callSign]);

  useEffect(() => {
    if (FLAGS.IS_UNDER_MAINTENANCE) {
      return;
    }

    if (isConnected && address && !verifyToken) {
      dispatch(authThunk(address))
        .unwrap()
        .then(() => {
          setIsAuthorized(true);
        });
    }
    if (isConnected && verifyToken) {
      if (getUsersError?.statusCode === 401) {
        dispatch(clearUsersError());
        setOpenAuthModal(true);
      } else getUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected, address, verifyToken]);

  return (
    <>
      {children}
      {!FLAGS.IS_UNDER_MAINTENANCE && (
        <>
          <NotificationsDialog
            open={notificationModalOpen && !isAuthLoading && !openAuthModal}
            handleClose={() => setNotificationModalOpen(false)}
            notifications={notifications?.[0]}
          />
          <AuthModal
            loading={isSignLoading || isAuthLoading}
            disabled={isSignLoading || isAuthLoading}
            open={openAuthModal}
            signMessage={() => signMessage({ message: nons })}
          />
        </>
      )}
    </>
  );
};

export default AuthProvider;
