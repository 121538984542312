import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Typography } from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export function Accordions({ head, detail }) {
  return (
    <Accordion sx={{ bgcolor: '#3E3E57', borderRadius: '1rem !important' }}>
      <AccordionSummary
        sx={{ flexDirection: 'row-reverse' }}
        expandIcon={<ExpandMoreIcon sx={{ mr: 1 }} />}
      >
        <Typography variant="subtitle1">{head}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>{detail}</Typography>
      </AccordionDetails>
    </Accordion>
  );
}
