import { Toolbar, useMediaQuery, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { BottomNav } from './BottomNav';
import { SideBarNav } from './SidebarNav';
import { MovingGradient } from '../../components';

const CustomLayout = () => {
  const theme = useTheme();
  const mdUP = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <>
      <Box
        component="section"
        sx={{
          display: 'flex',
          columnGap: mdUP ? 5 : 0,
        }}
      >
        {mdUP && (
          <MovingGradient
            sx={{
              width: 400,
              height: 800,
              top: 0,
              left: 0,
              color: '#272089',
              opacity: '70%',
            }}
            locked
          />
        )}
        {mdUP && <SideBarNav />}
        <Outlet />
      </Box>
      {!mdUP && (
        <>
          <Toolbar sx={{ py: 8 }} />
          <BottomNav />
        </>
      )}
    </>
  );
};

export default CustomLayout;
