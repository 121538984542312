import { Alert, Box, DialogActions, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTimeoutEffect } from '@react-hookz/web';
import { CustomButton } from '../../../../games/modals/styles';
import {
  LoadingModal,
  Modal,
  PrimaryDarkButton,
  SuccessModal,
} from '../../../..';
import {
  FormProvider,
  RHFTNumericTextField,
  RHFTextField,
} from '../../../../hook-form';
import { useTransferWusd } from '../../../../../api';
import { useBreakpointDown } from '../../../../../hooks';

const SET_BALANCE_BUTTONS_STYLES = {
  minWidth: 'auto',
  padding: 1,
  ':hover': {
    bgcolor: '#000 !important',
  },
};
const SET_BALANCE_BUTTONS = [
  { text: '25%', styles: SET_BALANCE_BUTTONS_STYLES },
  { text: '50%', styles: SET_BALANCE_BUTTONS_STYLES },
  { text: '75%', styles: SET_BALANCE_BUTTONS_STYLES },
  { text: 'MAX', styles: SET_BALANCE_BUTTONS_STYLES },
];

const FormSchema = Yup.object().shape({
  walletAddress: Yup.string()
    .required('Wallet address is required')
    .matches(/^0x[a-fA-F0-9]{40}$/g, 'Address is not valid'),
  wusdAmount: Yup.string().required('WUSD Amount is required'),

  //
});
const defaultValues = {
  walletAddress: '',
  wusdAmount: '',
};
export function TransferModal({ open, handleClose, wusdBalance }) {
  const { enqueueSnackbar } = useSnackbar();
  const [loadingModalOpen, setLoadingModalOpen] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const isDownSm = useBreakpointDown('sm');

  const methods = useForm({
    resolver: yupResolver(FormSchema),
    defaultValues,
  });
  const { handleSubmit, getValues, setValue } = methods;
  const { walletAddress, wusdAmount } = getValues();

  const {
    getPrepareData,
    isLoadingAnyState,
    hash,
    write,
    isSuccessPrepareContract,
  } = useTransferWusd({
    onSettledTransaction() {
      setLoadingModalOpen(false);
      setValue('walletAddress', '');
      setValue('wusdAmount', '');
    },
    onSuccessTransaction() {
      setSuccessModalOpen(true);
    },
    onSubmittedTransaction() {
      setLoadingModalOpen(true);
    },
    transferAmount: wusdAmount,
  });

  const submitHandler = () => {
    // write?.();
    getPrepareData({
      receiver: walletAddress,
    })
      .unwrap()
      .catch((error) =>
        enqueueSnackbar(error.data.message, { variant: 'error' })
      );
  };

  useTimeoutEffect(
    () => {
      write?.();
    },
    isSuccessPrepareContract ? 0 : undefined
  );

  const handleBalance = (index) => {
    if (index === 0) {
      setValue('wusdAmount', ((+wusdBalance * 25) / 100).toString());
    } else if (index === 1) {
      setValue('wusdAmount', ((+wusdBalance * 50) / 100).toString());
    } else if (index === 2) {
      setValue('wusdAmount', ((+wusdBalance * 75) / 100).toString());
    } else setValue('wusdAmount', (+wusdBalance).toString());
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-describedby="transfer-modal"
        title="Transfer WUSD"
      >
        <div className="flex flex-col gap-y-1.5">
          <FormProvider
            onSubmit={handleSubmit(submitHandler)}
            methods={methods}
          >
            <Box display="flex" flexDirection="column" rowGap={2}>
              <RHFTextField
                name="walletAddress"
                InputLabelProps={{ shrink: true }}
                label="Wallet Address"
                color="secondary"
              />
              <Box display="flex" flexDirection="column" rowGap={1}>
                <RHFTNumericTextField
                  name="wusdAmount"
                  InputLabelProps={{ shrink: true }}
                  label="WUSD Amount"
                  color="secondary"
                  allowNegative={false}
                />{' '}
                <div className="flex items-center justify-between">
                  {' '}
                  <Typography
                    variant={isDownSm ? 'caption' : 'body2'}
                    className="hidden lg:flex items-center gap-x-1"
                  >
                    <span className=" font-bold">WUSD</span> balance :{' '}
                    {wusdBalance}
                  </Typography>
                  <Box
                    display="flex"
                    columnGap={1}
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    {SET_BALANCE_BUTTONS.map(({ styles, text }, index) => (
                      <PrimaryDarkButton
                        key={text}
                        sx={styles}
                        size={isDownSm ? 'small' : 'medium'}
                        variant="contained"
                        onClick={() => handleBalance(index)}
                      >
                        {text}
                      </PrimaryDarkButton>
                    ))}
                  </Box>
                </div>
              </Box>
            </Box>

            <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
              <CustomButton
                type="submit"
                disabled={
                  isLoadingAnyState ||
                  !wusdAmount ||
                  !walletAddress ||
                  +wusdAmount > +wusdBalance
                }
                sx={{ typography: 'subtitle2' }}
                className="bg-primary-dark"
                variant="contained"
              >
                Transfer
              </CustomButton>
            </DialogActions>
            <Alert
              className="!text-xs sm:!text-sm"
              severity="info"
              variant="filled"
            >
              WUSD can be transferred only within your community, both upwards
              or downwards.
            </Alert>
          </FormProvider>
        </div>
      </Modal>
      <LoadingModal open={loadingModalOpen} />
      <SuccessModal
        title="Transfer WUSD"
        content="Transfer was Successful"
        open={successModalOpen}
        handleClose={() => {
          setSuccessModalOpen(false);
          handleClose();
        }}
        hash={hash}
      />
    </>
  );
}
