import { Stack, Typography } from '@mui/material';
import MaintenanceIllustration from '../../assets/illustrations/MaintenanceIllustration';

export function MaintenanceView() {
  return (
    <Stack sx={{ alignItems: 'center' }}>
      <Typography variant="h3" sx={{ mb: 2 }} textAlign="center">
        Website currently updating
      </Typography>

      <Typography sx={{ color: 'text.secondary' }} textAlign="center">
        We are currently working hard on this page!
      </Typography>

      <MaintenanceIllustration sx={{ my: 10, height: 240 }} />
    </Stack>
  );
}
