/* eslint-disable camelcase */
export function removeProfile({ builder, isGenx = false, isFreeMint = false }) {
  return builder.mutation({
    query: ({ token_address, token_id }) => ({
      url: isGenx
        ? '/genx/twitter-profile'
        : isFreeMint
        ? '/free-mint/twitter-profile'
        : '/user/twitter-profile',
      method: 'DELETE',
      body: {
        token_address,
        token_id,
      },
    }),
  });
}
