import React from 'react';
import { useAccount } from 'wagmi';
import { useSelector } from 'react-redux';
import {
  selectCurrentVerifyToken,
  selectIsActive,
} from '../../redux/slices/authSlice';
import { NotConnected } from '../NotConnected';

export function AuthGuard({ children, withActivationGuard = false }) {
  const isActive = useSelector(selectIsActive);
  const { isConnected } = useAccount();
  const token = useSelector(selectCurrentVerifyToken);

  if (!isConnected || !token || (withActivationGuard && !isActive)) {
    return <NotConnected withActivationGuard={withActivationGuard} />;
  }

  return children;
}
