import { useContractRead } from 'wagmi';
import { PancakeSwapFactoryV2 } from '../abi';
import { tokens } from '../constants';
import { addresses } from '../utils';

export const useGetPair = () => {
  const { data: getPairData, isLoading: getPairLoading } = useContractRead({
    address: addresses.pancakeSwapFactoryV2,
    abi: PancakeSwapFactoryV2,
    functionName: 'getPair',
    args: [tokens[1].address, tokens[0].address],
    onSettled(data, error) {
      if (data) console.log(data);
      if (error) console.log(error);
    },
  });

  return {
    getPairData,
    getPairLoading,
  };
};
