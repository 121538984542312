import { useState } from 'react';
import { useTimeoutEffect } from '@react-hookz/web';
import { useSnackbar } from 'notistack';
import { useRewards } from '../../../api/queries/getRewards';
import { RewardsSlider } from '../RewardsSlider';
import { useClaimTokenRewards } from '../../../api/queries/contracts/useClaimTokenRewards';
import { LoadingModal, SuccessModal } from '../..';
import { TasksModal } from './TasksModal';
import { FLAGS } from '../../../constants';

export function RewardsSection({ title, description, type }) {
  const [loadingModalOpen, setLoadingModalOpen] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const rewardsQuery = useRewards({ type });
  const [paymentToken, setPaymentToken] = useState('wusd');
  const [openTaskModal, setOpenTaskModal] = useState(false);
  const [modalTasks, setModalTasks] = useState();

  const { enqueueSnackbar } = useSnackbar();
  const {
    write,
    getPrepareData,
    isSuccessPrepareContract,
    isLoadingAnyState,
    hash,
  } = useClaimTokenRewards({
    onSuccessTransaction() {
      setSuccessModalOpen(true);
      rewardsQuery?.refetch();
    },
    onSettledTransaction() {
      setLoadingModalOpen(false);
    },
    onSubmittedTransaction() {
      setLoadingModalOpen(true);
    },
  });

  useTimeoutEffect(
    () => {
      if (FLAGS.IS_CLAIM_DISABLED) {
        return;
      }
      write?.();
    },
    isSuccessPrepareContract ? 0 : undefined
  );

  return (
    <div className="flex flex-col gap-y-12">
      <div className="flex flex-col gap-y-1">
        <h3 className="text-3xl font-semibold font-fredoka">{title}</h3>
        <p className="text-sm opacity-60">{description}</p>
      </div>
      <RewardsSlider
        paymentToken={paymentToken}
        setPaymentToken={setPaymentToken}
        rewardsQuery={rewardsQuery}
        type={type}
        isLoadingAnyState={isLoadingAnyState}
        claimDisabled={isLoadingAnyState || FLAGS.IS_CLAIM_DISABLED}
        onClaimClick={() => {
          if (FLAGS.IS_CLAIM_DISABLED) {
            return;
          }

          getPrepareData({ type, currency: paymentToken })
            .unwrap()
            .catch((error) => {
              if (error.status === 424) {
                setModalTasks(JSON.parse(error.data.message));
                setOpenTaskModal(true);
              } else enqueueSnackbar(error.data.message, { variant: 'error' });
            });
        }}
      />
      <LoadingModal open={loadingModalOpen} />
      <SuccessModal
        title="Claim Reward"
        content="Your Claim was Successful"
        open={successModalOpen}
        handleClose={() => setSuccessModalOpen(false)}
        hash={hash}
      />
      <TasksModal
        data={modalTasks}
        onClose={() => setOpenTaskModal(false)}
        open={openTaskModal}
      />
    </div>
  );
}
