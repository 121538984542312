export function getV3Blobs({ builder, isGenx = false }) {
  return builder.query({
    query: ({ userId }) => ({
      url: isGenx ? '/user/genx/v3/blob' : `/user/v3/blob`,
      params: {
        user: userId,
      },
    }),
  });
}
