import { Box } from '@mui/material';

export function StakingHeader({ sx }) {
  return (
    <Box component="span" sx={sx}>
      <Box component="span" typography="h5">
        Staking
      </Box>
    </Box>
  );
}
