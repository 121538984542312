import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectNotifications } from '../../../redux/slices/authSlice';

export function useNotifications() {
  const notifications = useSelector(selectNotifications);
  const [notificationModalOpen, setNotificationModalOpen] = useState(false);

  useEffect(() => {
    const notificationTitle = window.localStorage.getItem('notification-title');
    const disabledNotification = window.localStorage.getItem(
      'disable-notifications'
    );

    if (notifications && notifications[0]?.title) {
      if (!JSON.parse(notificationTitle) || !JSON.parse(disabledNotification)) {
        setNotificationModalOpen(true);
      } else if (
        (notificationTitle === notifications[0]?.title &&
          !JSON.parse(disabledNotification)) ||
        JSON.parse(notificationTitle) !== notifications[0]?.title
      ) {
        setNotificationModalOpen(true);
      }
    } else {
      setNotificationModalOpen(false);
    }
  }, [notifications, notifications.title]);

  return {
    notificationModalOpen,
    setNotificationModalOpen,
  };
}
