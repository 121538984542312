import { useEffect, useState } from 'react';
import { Card } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useAccount } from 'wagmi';
// eslint-disable-next-line import/no-unresolved
import { useModal } from 'connectkit';
import { Contracts } from './contracts';
import {
  ApproveButtons,
  CurrencyPrices,
  MintActions,
  MintProgress,
  NftImage,
  SelectCurrency,
} from './components';
import { SuccessModal } from '../SuccessModal';
import { LoadingModal } from '../LoadingModal';
import { PrimaryLightButton } from '../ui';

const START_EVENT_DATE = new Date('September 08 18:15:00 2024 UTC');
const END_EVENT_DATE = new Date('September 08 18:30:00 2024 UTC');

export function MintGenx() {
  const [mintedAmount, setMintedAmount] = useState(1);
  const { enqueueSnackbar } = useSnackbar();
  const { isConnected } = useAccount();
  const { openProfile } = useModal();

  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  const { usdtPrice, wusdPrice, isPriceLoading } = Contracts.useCalcPrice({
    amount: mintedAmount,
  });

  const usdtApprove = Contracts.useUsdtApprove({ amount: usdtPrice ?? 1 });
  const {
    isAllowedToPay: isAllowedToPayUsdt,
    transaction: usdtTransactionApprove,
  } = usdtApprove;

  const wusdApprove = Contracts.useWusdApprove({ amount: wusdPrice ?? 1 });
  const {
    isAllowedToPay: isAllowedToPayWusd,
    transaction: wusdTransactionApprove,
  } = wusdApprove;

  const {
    write: mint,
    transaction: transactionMint,
    prepare: prepareMint,
  } = Contracts.useMint({ mintAmount: mintedAmount });

  useEffect(() => {
    if (usdtTransactionApprove.isSuccess || wusdTransactionApprove.isSuccess) {
      enqueueSnackbar('Token approval was successful', { variant: 'success' });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    mint.isError,
    transactionMint.isError,
    usdtTransactionApprove.isSuccess,
    wusdTransactionApprove.isSuccess,
  ]);

  useEffect(() => {
    if (mint.isError || transactionMint.isError) {
      setIsSuccessModalOpen(false);
    }

    if (transactionMint.isSuccess) {
      setIsSuccessModalOpen(true);
    }
  }, [mint.isError, transactionMint.isError, transactionMint.isSuccess]);

  const isStartTimerGreaterThanNow = START_EVENT_DATE.getTime() > Date.now();
  const isEndTimerGreaterThanNow = END_EVENT_DATE.getTime() > Date.now();

  return (
    <>
      <div className="flex flex-col gap-y-10">
        <MintProgress
          isEndTimerGreaterThanNow={isEndTimerGreaterThanNow}
          isStartTimerGreaterThanNow={isStartTimerGreaterThanNow}
          startDate={START_EVENT_DATE}
          endDate={END_EVENT_DATE}
          key={`${usdtPrice}-${isStartTimerGreaterThanNow}-${isEndTimerGreaterThanNow}-${wusdPrice}`}
        />
        <div className="mx-auto max-w-5xl w-full">
          <Card className="flex flex-col items-center gap-x-4 gap-y-8 sm:flex-row sm:items-start sm:justify-between">
            <div className="flex flex-col gap-y-4 sm:w-1/2 order-2 sm:order-1 p-4 sm:p-6">
              <SelectCurrency />
              <CurrencyPrices wusdPrice={wusdPrice} usdtPrice={usdtPrice} />
              <MintActions
                mintAmount={mintedAmount}
                setMintAmount={setMintedAmount}
              />
              <div className="flex flex-col">
                {isConnected && (
                  <ApproveButtons
                    mintAmount={mintedAmount}
                    usdtApprove={usdtApprove}
                    wusdApprove={wusdApprove}
                  />
                )}
                <PrimaryLightButton
                  className="text-sm bg-primary-dark text-white"
                  onClick={() => {
                    if (!isConnected) {
                      openProfile();
                      return;
                    }
                    if (prepareMint.error) {
                      enqueueSnackbar(prepareMint.error.reason, {
                        variant: 'warning',
                      });
                      return;
                    }
                    if (mint.write) {
                      mint.write();
                    }
                  }}
                  disabled={
                    !mintedAmount ||
                    isPriceLoading ||
                    (isConnected &&
                      (!isAllowedToPayUsdt || !isAllowedToPayWusd)) ||
                    mint.isLoading ||
                    transactionMint.isLoading
                  }
                >
                  Mint
                </PrimaryLightButton>
              </div>
            </div>
            <NftImage />
          </Card>
        </div>
      </div>
      <SuccessModal
        hash={mint.data?.hash}
        title="Successful Mint"
        content="Your NFT has been successfully minted 🎉"
        open={isSuccessModalOpen}
        handleClose={() => setIsSuccessModalOpen(false)}
      />
      <LoadingModal
        open={
          transactionMint.isLoading && !mint.isError && !transactionMint.isError
        }
      />
    </>
  );
}
