import { useAccount, useContractRead } from 'wagmi';
import { bsc } from '@wagmi/chains';
import BwjABI from '../../../ABI/baby-woj-balance.json';

export function useGetBwjBalance() {
  const { address } = useAccount();

  return useContractRead({
    address: '0x83F41c98d028842Ccc8060b4Ec7738Df3eb9a2e6',
    abi: BwjABI,
    functionName: 'balanceOf',
    args: [address],
    chainId: bsc.id,
    watch: true,
  });
}
