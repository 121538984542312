import React, { useEffect, useState } from 'react';
import { Box, DialogActions } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Header from '../Header';
import { CustomButton } from '../styles';
import { ProfileImgWrapper } from '../profile/styles';
import { Modal } from '../../..';

const NotificationsDialog = ({ open, handleClose, notifications }) => {
  const [checked, setChecked] = useState(false);
  const handleChange = (event) => {
    setChecked(event.target.checked);
    window.localStorage.setItem(
      'disable-notifications',
      JSON.stringify(event?.target.checked)
    );
  };

  const handleClick = () => {
    if (notifications?.link) {
      window.open(notifications?.link, '_blank');
    }
  };

  // eslint-disable-next-line consistent-return
  function createMarkup() {
    if (notifications?.description) {
      return { __html: notifications.description };
    }
  }

  useEffect(() => {
    if (notifications?.title && checked) {
      window.localStorage.setItem(
        'notification-title',
        JSON.stringify(notifications?.title)
      );
    }
  }, [checked, notifications?.title]);

  return (
    <Modal
      keepMounted={false}
      open={open}
      onClose={handleClose}
      aria-describedby="notification-modal"
    >
      <Header handleClose={handleClose} content={notifications?.title || ''} />
      {notifications?.image && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ProfileImgWrapper sx={{ cursor: 'pointer' }} onClick={handleClick}>
            <img
              src={notifications?.image}
              alt={notifications?.title}
              width="100%"
              height="100%"
            />
          </ProfileImgWrapper>
        </Box>
      )}
      {notifications?.description && (
        <div
          style={{ marginTop: '1rem' }}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={createMarkup()}
        />
      )}
      <Box sx={{ mt: 2 }}>
        <FormControlLabel
          control={<Checkbox checked={checked} onChange={handleChange} />}
          label="Don't show this again!"
        />
      </Box>
      <DialogActions sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CustomButton
          variant="contained"
          className="bg-primary-dark"
          onClick={handleClose}
        >
          Dismiss
        </CustomButton>
      </DialogActions>
    </Modal>
  );
};

export default NotificationsDialog;
