import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import gamesProfileReducer from '../slices/games-profile';
import authSliceReducer from '../slices/authSlice';
import tasksSliceReducer from '../slices/tasksSlice';
import { clientNFTsApi, homePageApi } from '../../api';
// import { twitterApi } from '../../services/twitter';

export const store = configureStore({
  reducer: {
    gamesProfile: gamesProfileReducer,
    auth: authSliceReducer,
    tasks: tasksSliceReducer,
    [clientNFTsApi.reducerPath]: clientNFTsApi.reducer,
    [homePageApi.reducerPath]: homePageApi.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      clientNFTsApi.middleware,
      homePageApi.middleware
    ),
});

setupListeners(store.dispatch);
