import { Box, Typography } from '@mui/material';
import { StakingItem } from './StakingItem';
import { useGetAllStakes } from '../contracts';

export function StakingList() {
  const { data: allStakes } = useGetAllStakes();

  if (!allStakes || allStakes.length === 0) return null;

  return (
    <Box display="flex" flexDirection="column" rowGap={3} my={3}>
      <Typography variant="h5" align="center">
        Staking List
      </Typography>
      {allStakes?.map((stakeInfo, index) => (
        <StakingItem
          key={`${stakeInfo?.amount + stakeInfo?.unlockTime}`}
          stakeInfo={stakeInfo}
          index={index}
        />
      ))}
    </Box>
  );
}
