import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isTweetMessageModalOpen: false,
  tweetMessage: '',
  type: 'tweet',
  extraId: null,
};

export const tasksSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    openTweetMessageModal: (state) => {
      state.isTweetMessageModalOpen = true;
    },
    closeTweetMessageModal: (state) => {
      state.isTweetMessageModalOpen = false;
    },
    setTweetMessage: (state, action) => {
      state.tweetMessage = action.payload;
    },
    setType: (state, action) => {
      state.type = action.payload;
    },
    setExtraId: (state, action) => {
      state.extraId = action.payload;
    },
  },
});

export const selectIsTweetMessageModalOpen = (state) =>
  state.tasks.isTweetMessageModalOpen;

export const selectTweetMessage = (state) => state.tasks.tweetMessage;
export const selectType = (state) => state.tasks.type;
export const selectExtraId = (state) => state.tasks.extraId;

export const {
  openTweetMessageModal,
  closeTweetMessageModal,
  setTweetMessage,
  setType,
  setExtraId,
} = tasksSlice.actions;

export default tasksSlice.reducer;
