import { SwipeableDrawer as MuiSwipeableDrawer } from '@mui/material';

export function SwipeableDrawer({
  open,
  handleClose,
  children,
  sx,
  onOpen,
  ...props
}) {
  const onOpenHandler = (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    onOpen();
  };

  return (
    <MuiSwipeableDrawer
      sx={{ zIndex: 999999999 }}
      open={open}
      onClose={handleClose}
      onOpen={onOpenHandler}
      anchor="bottom"
      PaperProps={{
        sx: {
          bgcolor: 'grey.900',
          py: 3,
          px: 4,
          ...sx,
        },
      }}
      {...props}
    >
      {children}
    </MuiSwipeableDrawer>
  );
}
