export const SECTION_PADDING = '2.125rem 2rem';

export const TWITTER = 'twitter';

export const ABOVE_TIDIO_ZINDEX = 999999999;

export const walletAddress = (address) =>
  `${address.substring(0, 6)}••••${address.substring(
    address.length - 5,
    address.length
  )}`;

export const MINT_WOJ_LINK = 'https://mint.woj.finance/';
export const MINT_GENX_LINK = 'https://genx.woj.finance/';
export const RARIBLE_LINK = 'https://rarible.com/wojakians/';
export const BUY_WOJ_LINK =
  'https://pancakeswap.finance/swap?outputCurrency=0x55f96c7005d7c684a65ee653b07b5fe1507c56ab';
export const TWITTER_LINK = 'https://twitter.com/wojfinance';
export const TELEGRAM_LINK = 'https://t.me/wojtoken';
