import React from 'react';
import { Box, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { pxToRem } from '../../../utils/getFontValue';

const Header = ({ handleClose, content = 'UPDATE ENERGY' }) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      mb: pxToRem(24),
    }}
  >
    <Typography fontFamily="Fredoka One" variant="h6">
      {content}
    </Typography>
    <CloseIcon
      sx={{ color: ' #FF849A', cursor: 'pointer', zIndex: 2000 }}
      onClick={handleClose}
    />
  </Box>
);

export default Header;
