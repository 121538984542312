import { times } from 'lodash';
import { useDispatch } from 'react-redux';
import { TaskCard, TaskCardSkeleton } from '../TaskCard';
import {
  openTweetMessageModal,
  setExtraId,
  setTweetMessage,
  setType,
} from '../../../../../redux/slices/tasksSlice';

export function TasksSection({
  tasksQuery,
  hideInfo,
  isRewardPage,
  rewardPageTasks,
}) {
  const dispatch = useDispatch();

  if (!isRewardPage) {
    const { data } = tasksQuery;

    if (!data) {
      return (
        <>
          {times(5).map((id) => (
            <TaskCardSkeleton key={id} />
          ))}
        </>
      );
    }

    if (data && data?.tasks.length === 0) {
      return (
        <div className="p-4 bg-primary-dark rounded-xl flex items-center justify-center min-h-[100px]">
          <h6 className="text-lg font-semibold">You have no active tasks</h6>
        </div>
      );
    }

    return (
      <>
        {data?.tasks.map((task) => (
          <TaskCard
            hideInfo={hideInfo}
            key={task.content}
            data={task}
            handleInfoClick={() => {
              dispatch(setTweetMessage(task?.content));
              dispatch(openTweetMessageModal());
              dispatch(setType(task.type));
              dispatch(setExtraId(task.extraId));
            }}
          />
        ))}
      </>
    );
  }

  if (!rewardPageTasks) {
    return (
      <>
        {times(5).map((id) => (
          <TaskCardSkeleton key={id} />
        ))}
      </>
    );
  }

  if (rewardPageTasks) {
    if (rewardPageTasks.length === 0) {
      return (
        <div className="p-4 bg-primary-dark rounded-xl flex items-center justify-center min-h-[100px]">
          <h6 className="text-lg font-semibold">You have no active tasks</h6>
        </div>
      );
    }
  }

  return (
    <>
      {rewardPageTasks?.map((task) => (
        <TaskCard
          hideInfo={hideInfo}
          key={task.content}
          data={task}
          handleInfoClick={() => {
            dispatch(setTweetMessage(task?.content));
            dispatch(openTweetMessageModal());
            dispatch(setType(task.type));
            dispatch(setExtraId(task.extraId));
          }}
        />
      ))}
    </>
  );
}
