import { ethers } from 'ethers';
import { formatUnits, parseUnits } from 'ethers/lib/utils';
import { isUndefined } from 'lodash';
import { useEffect, useState } from 'react';
import {
  useAccount,
  useContractRead,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from 'wagmi';
import {
  genxConfig,
  genxMintConfig,
  usdtConfig,
  wusdConfig,
} from '../constants';

export function useAllowance({
  config,
  spenderAddress,
  allowanceDecimals = 18,
}) {
  const { isConnected, address: clientAddress } = useAccount();
  const [allowance, setAllowance] = useState(0);

  useContractRead({
    functionName: 'allowance',
    args: [clientAddress, spenderAddress],
    enabled: Boolean(clientAddress),
    onSuccess(data) {
      setAllowance(Number(formatUnits(data, allowanceDecimals)));
    },
    watch: true,
    ...config,
  });

  useEffect(() => {
    if (!isConnected && allowance) {
      setAllowance(0);
    }
  }, [allowance, isConnected]);

  return {
    allowance,
  };
}

export function useApprove(config) {
  const [isAllowedToPay, setIsAllowedToPay] = useState(false);
  const { amount, allowance, contractConfig } = config;
  const expandedAmount = ethers.constants.MaxUint256;

  useEffect(() => {
    if (!isUndefined(allowance)) {
      setIsAllowedToPay(allowance > 0 && amount > 0 && allowance >= amount);
    }
  }, [allowance, amount]);

  const prepare = usePrepareContractWrite({
    ...contractConfig,
    functionName: 'approve',
    args: [genxMintConfig.address, expandedAmount],
    enabled: !isAllowedToPay,
  });

  const write = useContractWrite(prepare.config);

  const hash = write.data?.hash;

  const transaction = useWaitForTransaction({ hash, ...contractConfig });

  return {
    prepare,
    write,
    transaction,
    isAllowedToPay,
    allowance,
    txHash: hash,
  };
}

export function useUsdtApprove({ amount, useMaxApprove }) {
  const { allowance } = useAllowance({
    config: usdtConfig,
    spenderAddress: genxMintConfig.address,
  });

  return useApprove({
    allowance,
    amount,
    useMaxApprove,
    contractConfig: usdtConfig,
  });
}

export function useWusdApprove({ amount, useMaxApprove }) {
  const { allowance } = useAllowance({
    config: wusdConfig,
    spenderAddress: genxMintConfig.address,
    allowanceDecimals: 9,
  });

  return useApprove({
    allowance,
    amount,
    useMaxApprove,
    contractConfig: wusdConfig,
    amountDecimals: 9,
  });
}

export function useCalcPrice({ amount }) {
  const formattedAmount = formatUnits(amount, 0);

  const { data: publicPrice, isLoading: isPublicPriceLoading } =
    useContractRead({
      ...genxMintConfig,
      functionName: 'calcPriceNFTs',
      args: [formattedAmount],
      enabled: !isUndefined(amount),
    });

  const prices = !isUndefined(publicPrice)
    ? [
        Number(formatUnits(publicPrice[0], 9)),
        Number(formatUnits(publicPrice[1], 18)),
      ]
    : undefined;

  return {
    wusdPrice: prices?.[0],
    usdtPrice: prices?.[1],
    isPriceLoading: isPublicPriceLoading,
  };
}

export function useMint({ mintAmount }) {
  const parsedMintAmount = parseUnits(String(mintAmount), 0);

  const prepare = usePrepareContractWrite({
    ...genxMintConfig,
    functionName: 'mint',
    args: [parsedMintAmount],
    enabled: Boolean(mintAmount),
  });

  const write = useContractWrite(prepare.config);

  const transaction = useWaitForTransaction({
    hash: write.data?.hash,
    ...genxMintConfig,
  });

  return {
    prepare,
    write,
    transaction,
  };
}

export function useTotalSupply() {
  const { data, isLoading, error } = useContractRead({
    functionName: 'totalSupply',
    watch: true,
    ...genxConfig,
  });

  return {
    totalSupply: isUndefined(data) ? 0 : Number(formatUnits(data, 0)),
    isLoadingTotalSupply: isLoading,
    useTotalSupplyLoadError: error,
  };
}

export function useMintBasePrice() {
  const { data, isLoading, error } = useContractRead({
    functionName: 'MINT_BASE_PRICE',
    watch: true,
    ...genxConfig,
  });

  return {
    basePrice: isUndefined(data) ? 0 : Number(formatUnits(data, 0)),
    isLoadingBasePrice: isLoading,
    basePriceLoadingError: error,
  };
}
