import React from 'react';
import { Box, Typography } from '@mui/material';
import { useBreakpointDown } from '../../../../../hooks';

export function BalanceWrapper({
  title,
  color,
  sx,
  wrapperSx,
  titleSx,
  children,
}) {
  const isDownLg = useBreakpointDown('lg');

  return (
    <Box
      bgcolor="#000"
      borderRadius="1rem"
      position="relative"
      display="flex"
      flexDirection={!isDownLg ? 'column' : 'row'}
      sx={sx}
    >
      <Box
        width={isDownLg ? '3.25rem' : '100%'}
        bgcolor="#000"
        borderRadius="1rem"
        padding="1rem"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="subtitle1"
          whiteSpace="nowrap"
          sx={{ transform: isDownLg && 'rotate(270deg)', ...titleSx }}
        >
          {title}
        </Typography>
      </Box>
      <Box
        padding="1.5rem"
        width="100%"
        bgcolor={color}
        height="100%"
        borderRadius="1rem"
        sx={wrapperSx}
      >
        {children}
      </Box>
    </Box>
  );
}
