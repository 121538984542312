import { Box } from '@mui/material';
import { SwipeableDrawer } from '..';

export function WarningDrawer({
  open,
  handleClose,
  onOpen,
  children,
  ...props
}) {
  return (
    <SwipeableDrawer
      open={open}
      onOpen={onOpen}
      handleClose={handleClose}
      sx={{ bgcolor: '#000', py: '3rem', px: '1.5rem' }}
      {...props}
    >
      <Box
        position="absolute"
        left="50%"
        top="1rem"
        sx={{ transform: 'translateX(-50%)' }}
      >
        <Box
          width="3.5rem"
          height="0.25rem"
          borderRadius="0.4375rem"
          bgcolor="#2F2E2E"
        />
      </Box>
      {children}
    </SwipeableDrawer>
  );
}
