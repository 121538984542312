import { Suspense, lazy } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

export const Home = Loadable(lazy(() => import('../pages/Home')));
export const MyProfile = Loadable(lazy(() => import('../pages/MyProfile')));
export const GenxProfile = Loadable(
  lazy(() => import('../pages/GenxProfilePage'))
);
export const Banner = Loadable(lazy(() => import('../pages/Banner')));
export const Rewards = Loadable(lazy(() => import('../pages/Rewards')));
export const Events = Loadable(lazy(() => import('../pages/Events')));
export const Sponsors = Loadable(lazy(() => import('../pages/Sponsors')));
export const Swap = Loadable(lazy(() => import('../pages/Swap')));
export const Staking = Loadable(lazy(() => import('../pages/Staking')));
export const Mint = Loadable(lazy(() => import('../pages/Mint')));
export const MintGenx = Loadable(lazy(() => import('../pages/MintGenxPage')));
export const Transfer = Loadable(lazy(() => import('../pages/Transfer')));
export const Faq = Loadable(lazy(() => import('../pages/Faq')));
export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
export const Page403 = Loadable(lazy(() => import('../pages/Page403')));
