import React from 'react';
import { Avatar, Box, Typography } from '@mui/material';
import { Logo as LogoImage, CHRISTMAS_LOGO } from '../../../assets/img';

export function Logo({ sx, christmas }) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        columnGap: 1.5,
        ...sx,
      }}
    >
      <Avatar
        sx={{ width: '3rem', height: '3rem', background: '#fff' }}
        src={christmas ? CHRISTMAS_LOGO : LogoImage}
      />
      <Box>
        <Typography variant="subtitle1">Wojak</Typography>
        <Typography variant="body2">Finance</Typography>
      </Box>
    </Box>
  );
}
