import { useAccount, useContractRead } from 'wagmi';
import { bsc } from '@wagmi/chains';
import { WUSD_CONTRACT_ADDRESS as address } from './address';
import { WUSD_CONTRACT_ABI as abi } from './wusd-abi';

export function useGetWusdBalance() {
  const { address: walletAddress } = useAccount();

  return useContractRead({
    address,
    abi,
    functionName: 'balanceOf',
    args: [walletAddress],
    chainId: bsc.id,
    watch: true,
  });
}
