import { useAccount, useContractRead } from 'wagmi';
import { bsc } from '@wagmi/chains';
import { MINT_ABI as abi } from '../../../../ABI';
import { MINT_CONTRACT_ADDRESS as address } from './address';

export function useGetPriceEveryNft() {
  const { address: clientAddress } = useAccount();

  return useContractRead({
    address,
    abi,
    functionName: 'priceEveryNFT',
    chainId: bsc.id,
    enabled: Boolean(clientAddress),
    watch: true,
  });
}
