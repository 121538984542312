import {
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from 'wagmi';
import { STAKING_CONTRACT_ADDRESS } from './constants';
import { STAKING_ABI } from './abi';
import { getBigNumber } from '../../../utils';

export const useWithdraw = ({
  stakeId,
  onSubmittedTransaction,
  onSuccessTransaction,
  onSettledTransaction,
  onErrorSubmittedTransaction,
  onErrorTransaction,
  onErrorPrepareTransaction,
}) => {
  const {
    config,
    error: prepareError,
    isError: isPrepareError,
  } = usePrepareContractWrite({
    address: STAKING_CONTRACT_ADDRESS,
    abi: STAKING_ABI,
    functionName: 'withdraw',
    args: [stakeId],
    overrides: { gasPrice: getBigNumber('4', 'gwei'), gasLimit: 550000 },
    enabled: Boolean(stakeId),
    onError(error) {
      onErrorPrepareTransaction?.(error);
    },
  });

  const {
    data: withdrawData,
    isLoading: isLoadingWriteContract,
    isSuccess: isSuccessWriteContract,
    write,
  } = useContractWrite({
    ...config,
    onSuccess() {
      onSubmittedTransaction?.();
    },
    onError(error) {
      onErrorSubmittedTransaction?.(error);
    },
  });

  const {
    isLoading: isLoadingWaitForTransaction,
    data: transactionReceipt,
    isSuccess: isSuccessTransaction,
    isError: isErrorTransaction,
    error: errorTransaction,
  } = useWaitForTransaction({
    hash: withdrawData?.hash,

    onSuccess(data) {
      onSuccessTransaction?.(data);
    },
    onSettled() {
      onSettledTransaction?.();
    },
    onError(error) {
      onErrorTransaction?.(error);
    },
  });

  return {
    hash: withdrawData?.hash,
    prepareError,
    isLoadingWriteContract,
    isSuccessWriteContract,
    write,
    isLoadingWaitForTransaction,
    transactionReceipt,
    isSuccessTransaction,
    isPrepareError,
    withdrawData,
    isErrorTransaction,
    errorTransaction,
  };
};
