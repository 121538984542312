import { Box } from '@mui/material';
import { BasicStaking } from './BasicStaking';

export function StakingAction() {
  return (
    <Box
      display="flex"
      flexDirection={{
        xs: 'column',
        sm: 'row',
      }}
      gap={1}
    >
      {/* <RewardSection /> */}
      <BasicStaking />
    </Box>
  );
}
