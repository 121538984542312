import { PROFILE, REFERRAL } from './constants';
import { RewardsSection } from './RewardSection';
import { MaintenanceView } from '../MaintenanceView';
import { AuthGuard } from '../auth-guard';

export function Rewards({ underMaintenance }) {
  if (underMaintenance) {
    return <MaintenanceView />;
  }

  return (
    <AuthGuard>
      <div className="flex flex-col gap-y-20 pb-6">
        <RewardsSection
          title="Profile"
          description="Your twitter profile rewards"
          type={PROFILE}
        />
        <RewardsSection
          title="Community"
          description="Your community rewards"
          type={REFERRAL}
        />
      </div>
    </AuthGuard>
  );
}
