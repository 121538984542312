import { useSelector } from 'react-redux';
import { useAccount } from 'wagmi';
import { selectCurrentVerifyToken } from '../../../redux/slices/authSlice';
import { Api } from '../../services';

export function useGetTasks() {
  const { isConnected } = useAccount();

  const token = useSelector(selectCurrentVerifyToken);

  const skip = !token || !isConnected;

  return Api.useGetTasksQuery('', { skip });
}
