import { alpha, styled } from '@mui/material';
import { pxToRem } from '../../../utils/getFontValue';

export const StyledPaper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'profileModal',
})(({ theme, profileModal }) => ({
  background: ' rgba(21, 24, 35, 0.4)',
  boxShadow: `0px 0px 196px ${alpha(
    theme.palette.primary.light,
    0.6
  )}, inset 0px -5.68965px 13.6552px ${alpha(
    theme.palette.primary.light,
    0.6
  )}, inset 0px 2.27586px 4.55172px rgba(255, 255, 255, 0.1) !important`,
  backdropFilter: `blur(${pxToRem(40)})`,
  borderRadius: pxToRem(48),
  padding: '32px 36px',
  minWidth: `${pxToRem(300)}`,

  ...(!profileModal && {
    [theme.breakpoints.up('md')]: {
      minWidth: pxToRem(551),
      width: pxToRem(551),
    },
  }),

  ...(profileModal && {
    [theme.breakpoints.down('sm')]: {
      maxHeight: '88vh !important',
      marginBottom: 'auto !important',
    },
    [theme.breakpoints.up('sm')]: {
      minWidth: '90vw',
      maxWidth: '90vw',
    },
    [theme.breakpoints.up('md')]: {
      minWidth: pxToRem(704),
      maxWidth: pxToRem(704),
    },
  }),
}));
