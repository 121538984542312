// ----------------------------------------------------------------------

export default function Badge() {
  return {
    MuiBadge: {
      styleOverrides: {
        dot: {
          minWidth: 7,
          width: 7,
          height: 7,
          borderRadius: '50%',
        },
      },
    },
  };
}
