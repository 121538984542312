import React from 'react';

const WalletIcon = ({ ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 18"
    fill="none"
    {...rest}
  >
    <g clipPath="url(#clip0_401_1036)">
      <path
        d="M13.305 17.0628H5.7225C5.4825 17.0628 5.2725 16.9128 5.19 16.6953C5.1075 16.4703 5.175 16.2228 5.355 16.0728C5.535 15.9228 5.7 15.7278 5.82 15.5178C6.06 15.1353 6.18 14.7003 6.18 14.2578C6.18 12.9153 5.085 11.8203 3.7425 11.8203C3.1875 11.8203 2.6625 12.0078 2.22 12.3603C2.0475 12.4953 1.8225 12.5178 1.6275 12.4278C1.4325 12.3378 1.3125 12.1353 1.3125 11.9178V8.64034C1.3125 6.77282 2.8275 5.25781 4.695 5.25781H13.305C15.1725 5.25781 16.6875 6.77282 16.6875 8.64034V9.72034C16.6875 10.0278 16.4325 10.2828 16.125 10.2828H14.61C14.3475 10.2828 14.1075 10.3803 13.935 10.5603L13.9275 10.5678C13.7175 10.7703 13.62 11.0478 13.6425 11.3328C13.6875 11.8278 14.16 12.2253 14.7 12.2253H16.125C16.4325 12.2253 16.6875 12.4803 16.6875 12.7878V13.6803C16.6875 15.5478 15.1725 17.0628 13.305 17.0628ZM6.885 15.9378H13.305C14.55 15.9378 15.5625 14.9253 15.5625 13.6803V13.3503H14.7C13.5675 13.3503 12.6075 12.5103 12.5175 11.4303C12.4575 10.8153 12.6825 10.2078 13.1325 9.76534C13.5225 9.36784 14.0475 9.15034 14.61 9.15034H15.5625V8.63284C15.5625 7.38781 14.55 6.3753 13.305 6.3753H4.695C3.45 6.3753 2.4375 7.38781 2.4375 8.63284V10.9428C2.8575 10.7778 3.3 10.6878 3.75 10.6878C5.715 10.6878 7.3125 12.2853 7.3125 14.2503C7.3125 14.8428 7.1625 15.4278 6.885 15.9378Z"
        fill="white"
      />
      <path
        d="M1.875 9.87C1.5675 9.87 1.3125 9.615 1.3125 9.3075V5.88004C1.3125 4.76254 2.0175 3.75001 3.06 3.35251L9.015 1.10251C9.6225 0.870006 10.3125 0.952536 10.845 1.32754C11.385 1.70254 11.7 2.31004 11.7 2.96254V5.81252C11.7 6.12002 11.445 6.37502 11.1375 6.37502C10.83 6.37502 10.575 6.12002 10.575 5.81252V2.96254C10.575 2.67754 10.44 2.41502 10.2 2.25002C9.96 2.08502 9.675 2.04752 9.405 2.15252L3.45 4.40252C2.8425 4.63502 2.43 5.22754 2.43 5.88004V9.3075C2.4375 9.6225 2.1825 9.87 1.875 9.87Z"
        fill="white"
      />
      <path
        d="M14.7003 13.3504C13.5678 13.3504 12.6078 12.5104 12.5178 11.4304C12.4578 10.8079 12.6828 10.2004 13.1328 9.75789C13.5153 9.36789 14.0403 9.15039 14.6028 9.15039H16.1628C16.9053 9.17289 17.4753 9.75789 17.4753 10.4779V12.0229C17.4753 12.7429 16.9053 13.3279 16.1853 13.3504H14.7003ZM16.1478 10.2754H14.6103C14.3478 10.2754 14.1078 10.3729 13.9353 10.5529C13.7178 10.7629 13.6128 11.0479 13.6428 11.3329C13.6878 11.8279 14.1603 12.2254 14.7003 12.2254H16.1703C16.2678 12.2254 16.3578 12.1354 16.3578 12.0229V10.4779C16.3578 10.3654 16.2678 10.2829 16.1478 10.2754Z"
        fill="white"
      />
      <path
        d="M10.5 9.5625H5.25C4.9425 9.5625 4.6875 9.3075 4.6875 9C4.6875 8.6925 4.9425 8.4375 5.25 8.4375H10.5C10.8075 8.4375 11.0625 8.6925 11.0625 9C11.0625 9.3075 10.8075 9.5625 10.5 9.5625Z"
        fill="white"
      />
      <path
        d="M3.75 17.8125C2.505 17.8125 1.335 17.1525 0.705002 16.08C0.367502 15.54 0.1875 14.9025 0.1875 14.25C0.1875 13.155 0.674999 12.1425 1.5225 11.4675C2.1525 10.965 2.9475 10.6875 3.75 10.6875C5.715 10.6875 7.3125 12.285 7.3125 14.25C7.3125 14.9025 7.1325 15.54 6.795 16.0875C6.615 16.4025 6.3675 16.6875 6.0825 16.9275C5.46 17.4975 4.6275 17.8125 3.75 17.8125ZM3.75 11.8125C3.195 11.8125 2.67 12 2.2275 12.3525C1.65 12.81 1.3125 13.5075 1.3125 14.25C1.3125 14.6925 1.4325 15.1275 1.665 15.5025C2.1075 16.2525 2.8875 16.6875 3.75 16.6875C4.3425 16.6875 4.9125 16.47 5.3475 16.08C5.5425 15.915 5.7075 15.72 5.8275 15.51C6.0675 15.1275 6.1875 14.6925 6.1875 14.25C6.1875 12.9075 5.0925 11.8125 3.75 11.8125Z"
        fill="white"
      />
      <path
        d="M4.8675 14.7979H2.625C2.3175 14.7979 2.0625 14.5429 2.0625 14.2354C2.0625 13.9279 2.3175 13.6729 2.625 13.6729H4.8675C5.175 13.6729 5.43 13.9279 5.43 14.2354C5.43 14.5429 5.1825 14.7979 4.8675 14.7979Z"
        fill="white"
      />
      <path
        d="M3.75 15.9446C3.4425 15.9446 3.1875 15.6896 3.1875 15.3821V13.1396C3.1875 12.8321 3.4425 12.5771 3.75 12.5771C4.0575 12.5771 4.3125 12.8321 4.3125 13.1396V15.3821C4.3125 15.6971 4.0575 15.9446 3.75 15.9446Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_401_1036">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default WalletIcon;
