export function getAllEnergyUpInquiry({ builder, isGenx = false }) {
  return builder.query({
    query: ({ currency }) => ({
      url: isGenx
        ? '/genx/energy-up/all/inquiry'
        : '/transaction/energy-up/all/inquiry',
      params: {
        currency,
      },
    }),
  });
}
