export function activateSpecialReward({ builder, isGenx = false }) {
  return builder.mutation({
    query: (body) => ({
      url: isGenx
        ? '/user/genx/activate-special-reward'
        : '/user/activate-special-reward',
      method: 'POST',
      body,
    }),
  });
}
