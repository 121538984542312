import { Box, CircularProgress, Typography } from '@mui/material';

export function CircularProgressWithLabel({
  wrapperSx,
  text,
  textVariant = 'subtitle2',
  typographyProps,
  ...props
}) {
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...wrapperSx,
      }}
    >
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant={textVariant} component="div" {...typographyProps}>
          {text}
        </Typography>
      </Box>
    </Box>
  );
}
