import { Button, DialogContent, Typography } from '@mui/material';
import React from 'react';
import Header from '../games/modals/Header';
import { Modal } from '../ui';

export function SuccessModal({ open, handleClose, hash, title, content }) {
  return (
    <Modal open={open} onClose={handleClose} aria-describedby="success-modal">
      <Header handleClose={handleClose} content={title} />
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: 2,
          alignItems: 'center',
        }}
      >
        <Typography variant="subtitle1" align="center">
          {content}
        </Typography>
        <Button
          variant="outlined"
          color="secondary"
          href={`https://bscscan.com/tx/${hash}`}
          onClick={handleClose}
          target="_blank"
        >
          View on Bscscan
        </Button>
      </DialogContent>
    </Modal>
  );
}
