import { Box, Button, Divider, Typography, alpha } from '@mui/material';
import { FormattedNumber } from 'react-intl';
import Alert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';
import { BalanceWrapper } from '../page-profile/ProfileBalance/TokensBalance';
import useResponsive from '../../hooks/useResponsive';
import { SwapIcon, WalletIcon } from '../../assets/svg';
import { BlackButton, PrimaryLightButton } from '../ui';

export function ClaimRewards({
  firstRewardAmount,
  firstRewardLimit,
  firstTokenIcon,
  firstTokenName,
  firstClaimAction,
  firstDisabledClaim,
  secondRewardAmount,
  secondRewardLimit,
  secondTokenIcon,
  secondTokenName,
  secondClaimAction,
  secondDisabledClaim,
  displayConvert,
  isConvertOnLeft = false,
  onConvertClick,
  disabledConvert,
  isOldBwj,
  isSingleSection = false,
  ...props
}) {
  const isUpLg = useResponsive('up', 'lg');
  const navigate = useNavigate();

  return (
    <BalanceWrapper color="primary.dark" title="Rewards" {...props}>
      <div className="w-full">
        {' '}
        <Alert
          icon={false}
          variant="outlined"
          severity="info"
          className="mb-3 text-xs sm:text-sm items-center"
          action={
            <Button
              variant="outlined"
              color="inherit"
              size="small"
              className="text-xs sm:text-sm py-1.5"
              onClick={() => navigate('/rewards')}
            >
              rewards
            </Button>
          }
        >
          Claim your rewards directly from rewards page
        </Alert>
      </div>

      <Box
        display="flex"
        flexDirection={isUpLg ? 'row' : 'column'}
        height={isUpLg ? 360 : '100%'}
      >
        <SingleRewardSection
          rewardAmount={firstRewardAmount}
          rewardLimit={firstRewardLimit}
          tokenIcon={firstTokenIcon}
          tokenName={firstTokenName}
          claimReward={firstClaimAction}
          disabledClaim={firstDisabledClaim}
          isOldBwj={isOldBwj}
          displayConvert={displayConvert && isConvertOnLeft}
          onConvertClick={onConvertClick}
          disabledConvert={disabledConvert}
        />
        {!isSingleSection && (
          <>
            <Divider sx={{ m: 2 }} />
            <SingleRewardSection
              displayConvert={displayConvert && !isConvertOnLeft}
              onConvertClick={onConvertClick}
              disabledConvert={disabledConvert}
              sx={
                isUpLg && {
                  borderLeft: `1px solid ${alpha('#fff', 0.1)}`,
                  pl: '1.5rem',
                }
              }
              rewardAmount={secondRewardAmount}
              rewardLimit={secondRewardLimit}
              tokenIcon={secondTokenIcon}
              tokenName={secondTokenName}
              claimReward={secondClaimAction}
              disabledClaim={secondDisabledClaim}
            />
          </>
        )}
      </Box>
    </BalanceWrapper>
  );
}

function SingleRewardSection({
  tokenName,
  rewardAmount,
  rewardLimit,
  tokenIcon: TokenIcon,
  claimReward,
  disabledClaim,
  displayConvert,
  onConvertClick,
  isOldBwj,
  disabledConvert,
  sx,
}) {
  const isUpLg = useResponsive('up', 'lg');

  return (
    <Box display="flex" columnGap={1.5} width="100%" sx={sx}>
      <TokenIcon
        sx={{ alignSelf: 'start', width: '1.5rem', height: '1.5rem', mt: 1 }}
      />
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        justifyContent="end"
        height="100%"
      >
        <Box
          display="flex"
          flexDirection="column"
          rowGap={2}
          height="100%"
          justifyContent="space-between"
        >
          <Box>
            <Box>
              <Typography variant="caption" color="text.secondary">
                {tokenName}
              </Typography>
              <Typography variant="subtitle1">
                <FormattedNumber
                  value={rewardAmount}
                  minimumFractionDigits={2}
                />
              </Typography>
            </Box>
            <Box
              mt="1rem"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              {isOldBwj ? (
                <Typography
                  variant="caption"
                  color="text.secondary"
                  whiteSpace="nowrap"
                >
                  Claim your reward
                </Typography>
              ) : (
                <Box display="flex" flexDirection={isUpLg ? 'flex' : 'column'}>
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    whiteSpace="nowrap"
                  >
                    Claim Limit:
                  </Typography>
                  <Typography variant="caption" whiteSpace="nowrap">
                    <FormattedNumber value={rewardLimit} />
                    &nbsp;
                    {tokenName}
                  </Typography>
                </Box>
              )}
              {!isUpLg && (
                <Box display="flex" flexDirection="column" rowGap={1}>
                  <PrimaryLightButton
                    disabled={disabledClaim}
                    onClick={claimReward}
                    sx={{ px: 2 }}
                    startIcon={<WalletIcon width="1.25rem" height="1.25rem" />}
                  >
                    Claim
                  </PrimaryLightButton>
                  {displayConvert && (
                    <BlackButton
                      disabled={disabledConvert}
                      onClick={onConvertClick}
                      sx={{ px: 2 }}
                      startIcon={<SwapIcon width="1.25rem" height="1.25rem" />}
                    >
                      Convert
                    </BlackButton>
                  )}
                </Box>
              )}
            </Box>
          </Box>
          {isUpLg && (
            <Box display="flex" justifyContent="end">
              <Box display="flex" alignItems="center" columnGap={1}>
                <PrimaryLightButton
                  disabled={disabledClaim}
                  onClick={claimReward}
                  sx={{ px: 2 }}
                  startIcon={<WalletIcon width="1.25rem" height="1.25rem" />}
                >
                  Claim
                </PrimaryLightButton>
                {displayConvert && (
                  <BlackButton
                    onClick={onConvertClick}
                    sx={{ px: 2 }}
                    startIcon={<SwapIcon width="1.25rem" height="1.25rem" />}
                  >
                    Convert
                  </BlackButton>
                )}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}
