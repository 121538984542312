export function Banner() {
  return (
    <div className="relative overflow-hidden min-h-[256px] w-full p-8 bg-primary-dark rounded-xl">
      <h3 className="font-semibold text-3xl">
        Experience the power of the Wojakian squad: beta version.
      </h3>
      <p className="text-white/80 mt-5 mb-8 lg:max-w-[55%]">
        Ready to earn while you tweet? Join the Woj Squad Beta, complete simple
        tweeting tasks, and claim your rewards. Stay tuned for the AI-powered
        revolution in Version 1.
      </p>
      {/* <BlackButton size="large" className="font-bold text-sm">
        View More Details
      </BlackButton> */}
      {/* <div className="rounded-xl min-h-[256px] absolute inset-0 overflow-hidden -z-10">
        <BannerImage />
      </div> */}
    </div>
  );
}
