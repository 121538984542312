import { Typography } from '@mui/material';

import { TasksModal } from './TasksModal';
import { TasksSection } from './TasksSection';

export function ActiveTasks({ tasksQuery, twitterNft }) {
  return (
    <>
      <div className="w-full lg:w-[70%]">
        <Typography mb="1.5rem" variant="h5">
          Active Tasks
        </Typography>
        <div className="flex flex-col gap-y-4">
          <TasksSection tasksQuery={tasksQuery} />
        </div>
      </div>
      <TasksModal twitterNft={twitterNft} />
    </>
  );
}
