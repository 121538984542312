export function inviteCodeTransactionPrice({ builder, isGenx = false }) {
  return builder.query({
    query: () => ({
      url: isGenx
        ? '/nft/genx/invite-code/packages'
        : '/nft/invite-code/packages',
      method: 'GET',
    }),
  });
}
