import { createSlice } from '@reduxjs/toolkit';
import { TWITTER } from '../../utils/commonValues';

const initialState = {
  stageLevel: 0,
  socialMedia: TWITTER,
  socialMediaId: '',
};

export const gamesProfile = createSlice({
  name: 'games/profile',
  initialState,
  reducers: {
    updateStage(state, action) {
      state.stageLevel = action.payload;
    },
    socialMediaHandler(state, action) {
      state.socialMedia = action.payload;
    },
    socialMediaIdHandler(state, action) {
      state.socialMediaId = action.payload;
    },
  },
});

export const stageLevel = (state) => state.gamesProfile.stageLevel;
export const socialMedia = (state) => state.gamesProfile.socialMedia;
export const socialMediaId = (state) => state.gamesProfile.socialMediaId;

export const { updateStage, socialMediaHandler, socialMediaIdHandler } =
  gamesProfile.actions;
export default gamesProfile.reducer;
