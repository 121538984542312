import { useSelector } from 'react-redux';
import { selectCurrentVerifyToken } from '../../../redux/slices/authSlice';
import { Api } from '../../services';

export function useRewards({ type }) {
  const token = useSelector(selectCurrentVerifyToken);
  return Api.useGetRewardsQuery(
    { type },
    {
      skip: !token,
    }
  );
}
