import { Navigate, useRoutes } from 'react-router-dom';

//
import {
  Page404,
  Home,
  MyProfile,
  Events,
  Sponsors,
  Page403,
  Banner,
  Swap,
  Faq,
  Staking,
  Mint,
  Transfer,
  Rewards,
  GenxProfile,
  MintGenx,
} from './elements';
import CompactLayout from '../layouts/compact';
import CustomLayout from '../layouts/normal-pages/CustomLayout';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <CustomLayout />,
      children: [
        { path: '', element: <Home /> },
        { path: 'profile', element: <MyProfile /> },
        { path: 'genx-profile', element: <GenxProfile /> },
        { path: 'banner', element: <Banner /> },
        { path: 'rewards', element: <Rewards /> },
        { path: 'events', element: <Events /> },
        { path: 'sponsors', element: <Sponsors /> },
        { path: 'swap', element: <Swap /> },
        { path: 'mint', element: <Mint /> },
        { path: 'mint-genx', element: <MintGenx /> },
        { path: 'transfer', element: <Transfer /> },
        { path: 'staking', element: <Staking /> },
        { path: 'faq', element: <Faq /> },
      ],
    },

    {
      element: <CompactLayout />,
      children: [
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
      ],
    },
    { path: '/wc', element: <Navigate to="/" replace /> },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
