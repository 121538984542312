import { Contract } from 'ethers';

import { addresses } from './addresses';

import pancakeSwapFactoryV2ABI from '../abi/PancakeSwapFactoryV2.json';
import pancakeSwapRouterV2ABI from '../abi/PancakeSwapRouterV2.json';
import erc20ABI from '../abi/erc20.json';
import { AddressZero } from './ethersUtils';
import { WOJ_SWAP_ROUTER_ABI } from '../abi';

export const getPancakeSwapFactoryV2Contract = (library) =>
  new Contract(
    addresses.pancakeSwapFactoryV2,
    pancakeSwapFactoryV2ABI,
    library
  );

export const getERC20Contract = (address, library) =>
  new Contract(address, erc20ABI, library);

export const getPancakeSwapRouterV2Contract = (library) =>
  new Contract(addresses.pancakeSwapRouterV2, pancakeSwapRouterV2ABI, library);
export const getWojSwapRouterV2Contract = (library) =>
  new Contract(addresses.wojSwapRouter, WOJ_SWAP_ROUTER_ABI, library);

export const checkPairAddress = async (addressA, addressB, provider) => {
  if (addressA && addressB) {
    const factoryContract = getPancakeSwapFactoryV2Contract(provider);

    const pairAddress = await factoryContract.getPair(addressA, addressB);

    if (pairAddress === AddressZero) throw new Error('Invalid pair');
  }
};
