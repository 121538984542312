import { styled } from '@mui/material';
import { Span } from '../Span';

export const StyledBadge = styled(Span, {
  shouldForwardProp: (prop) => prop !== 'isSuccess',
})(({ theme, isSuccess }) => ({
  ...theme.typography.caption,
  background: isSuccess ? theme.palette.success.light : '#3E3E57',
  color: isSuccess ? theme.palette.success.dark : '#fff',
  borderRadius: '0.25rem',
  padding: '0.1rem 0.2rem',
}));
