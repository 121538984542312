import { Box, CircularProgress, Typography } from '@mui/material';
import React from 'react';
import {} from '../../games/modals/SlideUpTransition';
import { CustomButton } from '../../games/modals/styles';
import { Modal } from '../../ui';

export function AuthModal({
  open,
  handleClose,
  signMessage,
  disabled,
  loading,
}) {
  return (
    <Modal
      keepMounted={false}
      open={open}
      onClose={handleClose}
      aria-describedby="authentication-modal"
      title="verification"
    >
      <Typography>
        To view your profile and gain access to the Wojakian app, please click
        on the "Sign" button and sign using your wallet. This will enhance the
        security and privacy of your account.
      </Typography>
      <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
        <CustomButton
          className="bg-primary-dark"
          disabled={disabled}
          variant="contained"
          onClick={signMessage}
          sx={{ color: '#fff', fontWeight: 'bold' }}
        >
          {loading ? (
            <CircularProgress sx={{ color: '#fff' }} size={28} />
          ) : (
            'Sign-in'
          )}
        </CustomButton>
      </Box>
    </Modal>
  );
}
