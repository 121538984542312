export function ShareIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      {...props}
    >
      <path
        d="M10.6657 16.223C9.78067 16.223 8.52817 15.6005 7.53817 12.623L6.99814 11.003L5.37814 10.463C2.40814 9.473 1.78564 8.2205 1.78564 7.33548C1.78564 6.45798 2.40814 5.19798 5.37814 4.20048L11.7457 2.07798C13.3357 1.54548 14.6632 1.70298 15.4807 2.51298C16.2982 3.32298 16.4557 4.65798 15.9232 6.24798L13.8007 12.6155C12.8032 15.6005 11.5507 16.223 10.6657 16.223ZM5.73064 5.27298C3.64564 5.97048 2.90314 6.79548 2.90314 7.33548C2.90314 7.8755 3.64564 8.7005 5.73064 9.3905L7.62067 10.0205C7.78567 10.073 7.92067 10.208 7.97317 10.373L8.60317 12.263C9.29317 14.348 10.1257 15.0905 10.6657 15.0905C11.2057 15.0905 12.0307 14.348 12.7282 12.263L14.8507 5.89548C15.2332 4.74048 15.1657 3.79548 14.6782 3.30798C14.1907 2.82048 13.2457 2.76048 12.0982 3.14298L5.73064 5.27298Z"
        fill="white"
      />
      <path
        d="M7.5831 10.8C7.44059 10.8 7.29809 10.7475 7.18559 10.635C6.96809 10.4175 6.96809 10.0575 7.18559 9.84L9.8706 7.1475C10.0881 6.93 10.4481 6.93 10.6656 7.1475C10.8831 7.365 10.8831 7.725 10.6656 7.9425L7.9806 10.635C7.8756 10.7475 7.7256 10.8 7.5831 10.8Z"
        fill="white"
      />
    </svg>
  );
}
