import { useDisconnect as useWagmiDisconnect } from 'wagmi';
import { useDispatch } from 'react-redux';
import { removeCookie } from '../utils/cookies';
import { logout } from '../redux/slices/authSlice';

export function useDisconnect() {
  const dispatch = useDispatch();
  const { disconnectAsync } = useWagmiDisconnect();

  const disconnect = async () => {
    await disconnectAsync();
    dispatch(logout());
    removeCookie('verification');
    removeCookie('verify');
  };

  return { disconnect };
}
