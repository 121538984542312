import PropTypes from 'prop-types';
import { useRef } from 'react';
import { SnackbarProvider as NotistackProvider } from 'notistack';
import { Collapse, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

SnackbarProvider.propTypes = {
  children: PropTypes.node,
};

export function SnackbarProvider({ children }) {
  const notistackRef = useRef(null);

  const onClose = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <>
      <NotistackProvider
        ref={notistackRef}
        dense
        maxSnack={1}
        autoHideDuration={3000}
        classes={{
          containerRoot: 'snackbar-zIndex',
        }}
        preventDuplicate
        TransitionComponent={Collapse}
        variant="success" // Set default variant
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        // With close as default
        action={(key) => (
          <IconButton
            size="small"
            onClick={onClose(key)}
            sx={{ p: 0.5, color: '#fff' }}
          >
            <CloseIcon />
          </IconButton>
        )}
      >
        {children}
      </NotistackProvider>
    </>
  );
}
