import { useAccount, useContractRead } from 'wagmi';
import { STAKING_CONTRACT_ADDRESS } from './constants';
import { STAKING_ABI } from './abi';

export function useGetTotalReward() {
  const { address } = useAccount();

  return useContractRead({
    address: STAKING_CONTRACT_ADDRESS,
    abi: STAKING_ABI,
    functionName: 'getTotalReward',
    args: [address],
  });
}
