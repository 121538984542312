import { useCallback, useState } from 'react';

export function useCopyToClipboard(text) {
  const [isCopied, setIsCopied] = useState(false);

  const copy = useCallback(() => {
    if (!navigator?.clipboard) {
      return;
    }

    navigator.clipboard.writeText(text);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  }, [text]);

  return { isCopied, copy };
}
