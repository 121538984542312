/* eslint-disable camelcase */
export function addGroup({ builder, isGenx = false }) {
  return builder.mutation({
    query: ({ parent_token_address, parent_token_id, subsets }) => ({
      url: isGenx ? '/genx/add-group' : '/nft/add-group',
      method: 'POST',
      body: {
        parent_token_address,
        parent_token_id,
        subsets,
      },
    }),
  });
}
