import { FormattedDate, FormattedNumber } from 'react-intl';
import { Skeleton } from '@mui/material';
import clsx from 'clsx';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import { BwjCoin, WusdCoin } from '../../ui';
import { BWJ_TOKEN } from '../constants';

export function RewardCard({
  amount,
  date,
  className,
  paymentToken,
  collection,
}) {
  return (
    <article
      className={clsx(
        'flex flex-col items-center justify-between bg-tertiary-darker/70 p-5 rounded-xl',
        className
      )}
    >
      <div className="flex items-center gap-x-3 h-full">
        {paymentToken === BWJ_TOKEN ? (
          <BwjCoin width="2.5rem" height="2.5rem" />
        ) : (
          <WusdCoin width="2.5rem" height="2.5rem" />
        )}
        <span className="text-xl text-green-500 font-bold">
          +
          <FormattedNumber
            value={paymentToken === BWJ_TOKEN ? amount : amount * 0.000014}
          />
        </span>
      </div>
      <div className="flex flex-col gap-y-1 text-sm">
        {/* <p className="text-xs font-bold opacity-70 whitespace-nowrap items-center flex gap-x-1">
          <span className="flex items-center gap-x-0.5">
            <PaidOutlinedIcon fontSize="small" />
            Earned amount:
          </span>
          <FormattedNumber
            value={paymentToken === BWJ_TOKEN ? amount : amount * 0.000014}
          />
        </p> */}
        <p className="text-xs font-bold opacity-70 whitespace-nowrap items-center flex gap-x-1">
          <span className="flex items-center gap-x-0.5">
            <AlarmOnIcon fontSize="small" />
            Earned at:
          </span>
          <FormattedDate
            day="2-digit"
            hour="numeric"
            minute="numeric"
            month="short"
            value={date}
            year="numeric"
          />
        </p>
        {collection && (
          <p className="text-xs text-center self-center font-bold opacity-70 whitespace-nowrap items-center flex gap-x-1">
            <span className="flex items-center gap-x-0.5">Earned by</span>
            {collection} collection
          </p>
        )}
      </div>
    </article>
  );
}

export function RewardCardSkeleton() {
  return (
    <article className="flex flex-col items-center gap-y-3 bg-tertiary-darker/70 p-5 rounded-lg">
      <div className="flex flex-col gap-y-1 items-center">
        <Skeleton width="2rem" height="2rem" variant="circular" />
        <Skeleton
          variant="text"
          className="text-xl text-success font-extrabold tracking-wider w-28"
        />
      </div>
      <div className="flex flex-col gap-y-2 text-sm">
        <Skeleton variant="text w-20" />
        <Skeleton variant="text w-20" />
      </div>
      <Skeleton variant="text w-24" />
    </article>
  );
}
