import { Box } from '@mui/material';
import { StakingTitle } from './StakingTitle';
import { StakingCards } from './StakingCards';
import { StakingStats } from './StakingStats';
import { StakingAction } from './StakingActions';
import { StakingList } from './StakingList';
import { AuthGuard } from '../auth-guard';

export function StakingPage() {
  return (
    <AuthGuard withActivationGuard>
      <Box display="flex" flexDirection="column" rowGap={2}>
        <StakingTitle />
        <StakingCards />
        <StakingStats />
        <StakingAction />
        <StakingList />
      </Box>
    </AuthGuard>
  );
}
