import {
  Alert,
  Box,
  Button,
  CircularProgress,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { FormattedNumber } from 'react-intl';
import { utils } from 'ethers';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';
import {
  useGetMinAmountStake,
  useStake,
  useStakeApproveToken,
} from '../../contracts';
import {
  WOJ_ABI,
  formatBigNumber,
  getBigNumber,
  useGetAllowance,
} from '../../../../swap';
import {
  STAKING_CONTRACT_ADDRESS,
  WOJ_TOKEN_CONTRACT_ADDRESS,
} from '../../contracts/constants';
import LoadingModal from '../../../games/modals/loading/LoadingModal';
import { useWhitelistedUsers } from '../../contracts/useWhitelistedUsers';
import { StakeInfoModal } from './StakeInfoModal';
import { SuccessModal } from '../../../SuccessModal';
import { useGetWojBalance } from '../../../../api';

export function BasicStaking() {
  const { enqueueSnackbar } = useSnackbar();
  const [loadingModalOpen, setLoadingModalOpen] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [stakingInfoModalOpen, setStakingInfoModalOpen] = useState(false);

  const [tokenAllowance, setTokenAllowance] = useState(1);
  const { data: isWhitelistedUser } = useWhitelistedUsers();
  const { data: wojBalance } = useGetWojBalance();
  const [stakingValue, setStakingValue] = useState('');
  const { data: minAmountStake } = useGetMinAmountStake();
  const {
    write: deposit,
    hash,
    isLoadingWaitForTransaction,
    isLoadingWriteContract,
    isPrepareError,
  } = useStake({
    amount: getBigNumber(stakingValue || '0', 9),
    onSubmittedTransaction() {
      setLoadingModalOpen(true);
    },
    onSettledTransaction() {
      setLoadingModalOpen(false);
      setStakingValue('');
    },
    onSuccessTransaction() {
      setSuccessModalOpen(true);
    },

    onErrorTransaction(error) {
      enqueueSnackbar(error?.reason || 'Something Went Wrong', {
        variant: 'error',
      });
    },
  });

  useEffect(() => {
    if (stakingValue.length > 18)
      setStakingValue(
        wojBalance
          ? Math.floor(Number(utils.formatUnits(wojBalance, 9))).toString()
          : 0
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stakingValue]);

  const { isLoading: getAllowanceLoading, isSuccess: getAllowanceSuccess } =
    useGetAllowance({
      tokenContractAddress: WOJ_TOKEN_CONTRACT_ADDRESS,
      abi: WOJ_ABI,
      swapContractAddress: STAKING_CONTRACT_ADDRESS,
      onSuccess(data) {
        setTokenAllowance(formatBigNumber(data, 9));
      },
    });

  const { write: approveTokenWrite, isLoading: approveTokenIsLoading } =
    useStakeApproveToken({
      onErrorTransaction(error) {
        enqueueSnackbar(error.reason || 'Something Went Wrong', {
          variant: 'error',
        });
      },
      tokenContractAddress: WOJ_TOKEN_CONTRACT_ADDRESS,
      abi: WOJ_ABI,
      onSuccessTransaction() {
        enqueueSnackbar('Approval was successful', {
          variant: 'success',
        });
      },
    });

  return (
    <>
      <Box display="flex" flexDirection="column" rowGap={2} width="100%">
        <Box
          display="flex"
          flexDirection="column"
          rowGap={1}
          bgcolor="grey.800"
          width="100%"
          px={2}
          py={1}
          borderRadius={2}
        >
          {!isWhitelistedUser && (
            <Alert sx={{ my: 1.5 }} variant="filled" severity="error">
              only Available for whitelist users
            </Alert>
          )}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="subtitle1"> Stake Amount</Typography>
            <Box display="flex" columnGap={0.5} alignItems="center">
              <Button
                size="small"
                variant="outlined"
                color="secondary"
                onClick={() =>
                  setStakingValue(
                    wojBalance
                      ? Math.floor(
                          Number(utils.formatUnits(wojBalance, 9))
                        ).toString()
                      : 0
                  )
                }
              >
                {
                  <FormattedNumber
                    value={wojBalance ? utils.formatUnits(wojBalance, 9) : 0}
                    maximumFractionDigits={0}
                  />
                }{' '}
                WOJ available
              </Button>
              <IconButton onClick={() => setStakingInfoModalOpen(true)}>
                <ReportOutlinedIcon color="secondary" />
              </IconButton>
            </Box>
          </Box>
          <Box>
            <Box
              display="flex"
              alignItems="center"
              columnGap={1}
              mt={3}
              width="100%"
            >
              <TextField
                helperText={
                  minAmountStake &&
                  `Min amount: ${formatBigNumber(minAmountStake, 9)}`
                }
                InputLabelProps={{ shrink: true }}
                label="Woj amount"
                size="small"
                value={stakingValue}
                onChange={(e) =>
                  setStakingValue(Math.floor(Number(e.target.value)).toString())
                }
                sx={{ width: '75%' }}
              />

              <Button
                sx={{ width: '25%', alignSelf: 'start' }}
                variant="contained"
                onClick={() =>
                  setStakingValue(
                    wojBalance
                      ? Math.floor(
                          Number(utils.formatUnits(wojBalance, 9))
                        ).toString()
                      : 0
                  )
                }
              >
                MAX
              </Button>
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            rowGap={1.5}
            justifyContent="center"
            mt={2}
          >
            <Button
              disabled={
                !isWhitelistedUser ||
                !stakingValue ||
                +stakingValue === 0 ||
                +stakingValue < +formatBigNumber(minAmountStake || '0', 9) ||
                +stakingValue > Number(utils.formatUnits(wojBalance, 9)) ||
                isLoadingWriteContract ||
                isLoadingWaitForTransaction ||
                +tokenAllowance < +formatBigNumber(wojBalance || '0', 9) ||
                isPrepareError
              }
              fullWidth
              variant="contained"
              onClick={() => deposit?.()}
            >
              {isLoadingWaitForTransaction || isLoadingWriteContract ? (
                <CircularProgress size={30} sx={{ color: '#fff' }} />
              ) : (
                <span>Stake Now</span>
              )}
            </Button>
            {tokenAllowance !== 1 &&
              +tokenAllowance < +formatBigNumber(wojBalance || '0', 9) &&
              !getAllowanceLoading &&
              getAllowanceSuccess && (
                <Button
                  variant="contained"
                  onClick={() => approveTokenWrite?.()}
                >
                  approve
                </Button>
              )}
          </Box>
        </Box>
      </Box>
      <LoadingModal open={loadingModalOpen || approveTokenIsLoading} />
      <SuccessModal
        title="Stake"
        content="Your Staking was Successful"
        open={successModalOpen}
        handleClose={() => setSuccessModalOpen(false)}
        hash={hash}
      />
      <StakeInfoModal
        open={stakingInfoModalOpen}
        handleClose={() => setStakingInfoModalOpen(false)}
      />
    </>
  );
}
