/* eslint-disable camelcase */
export function deleteFromGroups({ builder, isGenx = false }) {
  return builder.mutation({
    query: ({ token_address, token_id }) => ({
      url: isGenx ? '/genx/group' : '/nft/group',
      method: 'DELETE',
      body: {
        token_address,
        token_id,
      },
    }),
  });
}
