import React from 'react';
import { Link } from 'react-router-dom';
import { TasksSection } from '../../../page-events/Tasks/ActiveTasks/TasksSection';
import { Modal, ModalButton } from '../../..';
import { PATHS } from '../../../../routes/paths';

export function TasksModal({ data, open, onClose }) {
  return (
    <Modal maxWidth="xl" open={open} onClose={onClose} title="Active Tasks">
      <h6 className="mb-4">You haven't done this tasks</h6>
      {
        <div className="flex flex-col gap-y-4">
          <TasksSection isRewardPage rewardPageTasks={data} hideInfo />
        </div>
      }
      <ModalButton
        LinkComponent={Link}
        to={PATHS.events}
        variant="contained"
        className="!bg-primary mt-10"
      >
        Let's do it
      </ModalButton>
    </Modal>
  );
}
