import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import CelebrationOutlinedIcon from '@mui/icons-material/CelebrationOutlined';
import GamesOutlinedIcon from '@mui/icons-material/GamesOutlined';
import { uuid } from '../../utils';
import { PATHS } from '../../routes/paths';
import {
  EventsIcon,
  FaqIcon,
  HomeIcon,
  ProfileIcon,
  SponsorsIcon,
  SwapIcon,
  TransferIcon,
} from '../../assets/svg';

export const ROUTE_PATHS = [
  { id: uuid(), name: 'Home', icon: HomeIcon, path: PATHS.root },
  { id: uuid(), name: 'Wojak Profile', icon: ProfileIcon, path: PATHS.games },
  {
    id: uuid(),
    name: 'GenX Profile',
    icon: GamesOutlinedIcon,
    path: PATHS.genX,
  },
  {
    id: uuid(),
    name: 'Rewards',
    icon: CelebrationOutlinedIcon,
    path: PATHS.rewards,
  },
  { id: uuid(), name: 'Events', icon: EventsIcon, path: PATHS.events },
  {
    id: uuid(),
    name: 'Sponsors',
    icon: PeopleOutlinedIcon,
    path: PATHS.activationCode,
  },
  {
    id: uuid(),
    name: 'Swap',
    icon: SwapIcon,
    path: PATHS.swap,
  },
  {
    id: uuid(),
    name: 'Staking',
    icon: SponsorsIcon,
    path: PATHS.staking,
  },
  {
    id: uuid(),
    name: 'Mint',
    icon: CategoryOutlinedIcon,
    path: PATHS.mintGenx,
  },
  {
    id: uuid(),
    name: 'Transfer WUSD',
    icon: TransferIcon,
    path: PATHS.transfer,
  },
  {
    id: uuid(),
    name: 'Faq',
    icon: FaqIcon,
    path: PATHS.faq,
  },
];
