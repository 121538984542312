import { Button } from '@mui/material';
import { XIcon } from '../../../../../assets/svg';

export function TweetStep() {
  return (
    <div className="flex flex-col">
      <div className="flex flex-col gap-y-3">
        <p className="flex items-center gap-x-1 text-lg">
          Post this <span className="font-bold ">Tweet</span> on
          <span className="font-bold">
            <XIcon className="w-4 h-4 grayscale" />
          </span>
        </p>
        <p>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Atque
          expedita, ut fuga qui blanditiis sed omnis ipsum officiis dolores.
          Quas mollitia, harum natus deserunt earum ipsam voluptatum tempora
          laboriosam dolorum.
        </p>
      </div>
      <div className="flex items-center justify-center mt-6">
        <Button
          target="_blank"
          variant="contained"
          className="!bg-primary h-12 w-32 font-bold"
          href="https://x.com/"
        >
          Let's go
        </Button>
      </div>
    </div>
  );
}
