export function TransferIcon({
  color = '#fff',
  width = 24,
  height = 24,
  ...props
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={color}
      viewBox="0 0 16.933 16.933"
      {...props}
    >
      <g transform="translate(0 -280.067)">
        <path
          d="M8.467 280.596a7.912 7.912 0 00-3.278.705c-.321.146-.103.628.219.482a7.38 7.38 0 013.059-.658 7.404 7.404 0 017.408 7.408 7.36 7.36 0 01-1.576 4.554.265.265 0 00.027.355l.646.648h-2.01v-2.008l.61.608c.25.259.633-.127.373-.376l-1.058-1.058a.265.265 0 00-.452.188v2.91c0 .146.118.265.264.265h2.91a.265.265 0 00.187-.45l-.937-.942a7.893 7.893 0 001.545-4.694 7.942 7.942 0 00-7.937-7.937zm0 1.059c-.997 0-1.947.211-2.803.593-.332.14-.112.637.215.485a6.333 6.333 0 012.588-.55c3.51 0 6.35 2.84 6.35 6.35a6.321 6.321 0 01-.685 2.87c-.185.32.32.578.471.24a6.882 6.882 0 00-6.137-9.988zm-7.145.792a.265.265 0 00-.188.452l.939.941a7.891 7.891 0 00-1.544 4.693 7.942 7.942 0 0011.213 7.233c.322-.146.103-.629-.219-.483a7.37 7.37 0 01-3.056.659 7.406 7.406 0 01-5.84-11.968.265.265 0 00-.022-.35l-.644-.647h2.008v2.008l-.61-.608c-.25-.259-.633.126-.373.375l1.059 1.059c.166.17.455.05.453-.188v-2.91a.265.265 0 00-.266-.266zm7.144 1.852c-.914 0-1.803.297-2.535.844a.265.265 0 10.316.424 3.707 3.707 0 015.924 2.967 3.7 3.7 0 01-3.705 3.703 3.707 3.707 0 01-2.961-5.93.265.265 0 10-.422-.316 4.233 4.233 0 003.383 6.775 4.237 4.237 0 004.235-4.232 4.24 4.24 0 00-4.235-4.235zm-5.914.979a.266.266 0 00-.224.146 6.86 6.86 0 00-.743 3.11 6.885 6.885 0 009.683 6.285c.323-.144.108-.628-.215-.485a6.323 6.323 0 01-2.586.549 6.347 6.347 0 01-6.352-6.35c0-1.033.247-2.007.684-2.869a.265.265 0 00-.247-.386zm5.913.078c-.132 0-.264.088-.264.265v.561a1.326 1.326 0 00-1.057 1.295c0 .728.595 1.323 1.322 1.323.442 0 .793.353.793.794s-.351.794-.793.794-.793-.352-.793-.794c-.008-.345-.521-.345-.529 0 0 .637.455 1.172 1.057 1.296v.556c0 .353.529.353.529 0v-.556a1.328 1.328 0 001.059-1.296c0-.727-.595-1.324-1.323-1.324-.441 0-.793-.351-.793-.793s.352-.793.793-.793.793.352.793.793c-.008.36.538.36.53 0 0-.637-.457-1.172-1.06-1.295v-.561c0-.177-.132-.265-.264-.265z"
          vectorEffect="none"
        />
      </g>
    </svg>
  );
}
