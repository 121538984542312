import { Box, Typography } from '@mui/material';
import { FormattedNumber } from 'react-intl';

const TimeSegment = ({ time, horizontal, children, onlyDays }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: horizontal ? 'row' : 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <Box
      component="strong"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: !horizontal ? '40px' : 'auto',
        // color: 'primary.main',
      }}
    >
      <Typography component="div" variant="h6" sx={{ color: '#fff' }}>
        <FormattedNumber value={time} /> {onlyDays && 'Days'}
      </Typography>
    </Box>
    {!horizontal && (
      <Typography
        style={{
          opacity: 0.6,
          marginLeft: horizontal ? '5px' : 0,
          color: '#fff',
        }}
      >
        {children}
      </Typography>
    )}
  </Box>
);

export default TimeSegment;
