import { useAccount, useContractRead } from 'wagmi';
import { getBigNumber } from '../utils';

export const useGetAllowance = ({
  tokenContractAddress,
  abi,
  onSuccess,
  swapContractAddress,
}) => {
  const { address } = useAccount();
  return useContractRead({
    overrides: { gasPrice: getBigNumber('3', 'gwei'), gasLimit: 550000 },
    address: tokenContractAddress,
    abi,
    functionName: 'allowance',
    args: [address, swapContractAddress],
    enabled:
      Boolean(address) &&
      Boolean(tokenContractAddress) &&
      Boolean(swapContractAddress),
    onSuccess,
    watch: true,
  });
};
