import { Box, Typography } from '@mui/material';

export function StakingTitle() {
  return (
    <Box>
      <Typography align="center" variant="h3" mt={8}>
        Staking{' '}
        <Box component="span" color="primary.main">
          Pools
        </Box>
      </Typography>
      <Typography align="center" variant="body2" color="text.secondary" mt={1}>
        Choose a plan to start earning long term rewards
      </Typography>
    </Box>
  );
}
