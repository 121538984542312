import { Button, styled } from '@mui/material';
import { Link } from 'react-router-dom';
import { pxToRem } from '../../../utils/getFontValue';

export const Sidebar = styled('aside')(({ theme }) => ({
  padding: theme.spacing(2),
  borderRight: '1px solid #3A3A4B',
  flex: 0.28,
  height: '100vh',
  position: 'sticky',
  left: 0,
  top: 0,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  maxWidth: pxToRem(350),
  overflowY: 'auto',
}));

export const LoginButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  columnGap: theme.spacing(1),
  color: '#fff',
  padding: theme.spacing(2),
  fontWeight: 700,
}));

export const NavLink = styled(Link, {
  shouldForwardProp: (prop) => prop !== 'path' && prop !== 'pathname',
})(({ theme, pathname, path }) => ({
  display: 'flex',
  columnGap: theme.spacing(2),
  color: '#fff',
  background: path === pathname && theme.palette.primary.main,
  alignItems: 'center',
  cursor: 'pointer',
  padding: theme.spacing(1.5),
  borderRadius: theme.spacing(2),
  textDecoration: 'none',
  ':hover': {
    background: path !== pathname && theme.palette.primary.main,
  },
}));
