export function getCookie(name) {
  if (typeof document === 'undefined') {
    throw new Error(
      'getCookie() is not supported on the server. Fallback to a different value when rendering on the server.'
    );
  }

  const value = `; ${document.cookie}`;

  const parts = value.split(`; ${name}=`);

  if (parts.length === 2) {
    return parts[1].split(';').shift();
  }

  return undefined;
}

export function setCookie(name, value, exDays = 3) {
  const date = new Date();
  date.setTime(date.getTime() + exDays * 24 * 60 * 60 * 1000);
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${name}=${value};${expires};path=/`;
}

export function removeCookie(name) {
  document.cookie = `${name}=;path=/;max-age=0`;
}

export const parsedCookies = getCookie('verify')
  ? JSON.parse?.(getCookie('verify'))
  : null;
