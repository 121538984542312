/* eslint-disable camelcase */
export function verifyAllEnergyUpTransaction({ builder, isGenx = false }) {
  return builder.mutation({
    query: ({ tx_hash, data_hash }) => ({
      url: isGenx
        ? '/genx/energy-up/all/verify'
        : '/transaction/energy-up/all/verify',
      method: 'POST',
      body: { tx_hash, data_hash },
    }),
  });
}
