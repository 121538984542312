import { useState } from 'react';
import { useEventListener } from './useEventListener';

export const useScaledDownMousePosition = (scale = 0.4) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  useEventListener('mousemove', (event) => {
    const x = (event.clientX / window.innerWidth) * 100 * scale;
    const y = (event.clientY / window.innerHeight) * 100 * scale;
    setPosition({ x, y });
  });
  return position;
};
