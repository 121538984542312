import { verifyMessage } from 'ethers/lib/utils';
import { useDispatch } from 'react-redux';
import { useSignMessage as useSign } from 'wagmi';
import { verifyAuthThunk } from '../../../redux/thunks/auth-thunk';
import { useDisconnect } from '../../../hooks';

export function useSignMessage({ onSettled }) {
  const { disconnect } = useDisconnect();
  const dispatch = useDispatch();

  const { signMessage, isLoading } = useSign({
    onSuccess: async (data, variables) => {
      const signerAddress = verifyMessage(variables.message, data);
      dispatch(verifyAuthThunk({ wallet: signerAddress, signature: data }))
        .unwrap()
        .finally(onSettled);
    },
    onError() {
      disconnect();
    },
  });

  return {
    signMessage,
    isLoading,
  };
}
