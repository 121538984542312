import { Avatar, Box, Button, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import useResponsive from '../../../../hooks/useResponsive';
import { WojCoinIcon } from '../../../../assets/svg';
import { useWithdraw } from '../../contracts';
import LoadingModal from '../../../games/modals/loading/LoadingModal';
import { formatBigNumber } from '../../../../swap';
import CountdownTimer from '../../../timer/CountdownTimer';
import { SuccessModal } from '../../..';

export function StakingItem({ stakeInfo, index }) {
  const { enqueueSnackbar } = useSnackbar();
  const [loadingModalOpen, setLoadingModalOpen] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const isDownSm = useResponsive('down', 'sm');
  const {
    write: withdraw,
    hash,
    isLoadingWaitForTransaction,
    isLoadingWriteContract,
    isPrepareError,
  } = useWithdraw({
    stakeId: index + 1,
    // stakeId,
    onSubmittedTransaction() {
      setLoadingModalOpen(true);
    },
    onSettledTransaction() {
      setLoadingModalOpen(false);
    },
    onSuccessTransaction() {
      setSuccessModalOpen(true);
    },
    onErrorTransaction(error) {
      enqueueSnackbar(error.reason || 'Something Went Wrong', {
        variant: 'error',
      });
    },
  });
  // const date1 = getUnixTime(new Date());
  // const date2 = +formatBigNumber(stakeInfo?.unlockTime, 0) * 1000;
  // console.log(date2 - date1);

  return (
    <>
      <Box bgcolor="grey.900" width="100%" p={2} borderRadius={3}>
        <Box display="flex" justifyContent="space-around" alignItems="center">
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box display="flex" columnGap={1} alignItems="center">
              <Avatar
                src={WojCoinIcon}
                sx={{ width: '1.2rem', height: '1.2rem' }}
              />
              <Typography variant={isDownSm ? 'subtitle1' : 'h6'}>
                {formatBigNumber(stakeInfo?.amount, 9)}
              </Typography>
            </Box>
            <Typography
              variant={isDownSm ? 'caption' : 'body2'}
              color="text.secondary"
            >
              Staked
            </Typography>
          </Box>
          <Box
            borderRadius={3}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Box display="flex" columnGap={1} alignItems="center">
              <Avatar
                src={WojCoinIcon}
                sx={{ width: '1.2rem', height: '1.2rem' }}
              />
              <Typography variant={isDownSm ? 'subtitle1' : 'h6'}>
                {formatBigNumber(stakeInfo?.amountWithPrize, 9)}
              </Typography>
            </Box>
            <Typography
              variant={isDownSm ? 'caption' : 'body2'}
              color="text.secondary"
            >
              Staked with Reward
            </Typography>{' '}
          </Box>
          <Box
            borderRadius={3}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Typography variant={isDownSm ? 'subtitle1' : 'h6'}>
              {/* <FormattedDate
                value={+formatBigNumber(stakeInfo?.unlockTime, 0) * 1000}

                // day="numeric"
              /> */}
              {/* days */}
              <CountdownTimer
                onlyDays
                date={+formatBigNumber(stakeInfo?.unlockTime, 0) * 1000}
              />
            </Typography>
            <Typography
              variant={isDownSm ? 'caption' : 'body2'}
              color="text.secondary"
            >
              Withdraw date
            </Typography>{' '}
          </Box>
          {!isDownSm && (
            <Box
              borderRadius={3}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Button
                onClick={() => withdraw?.()}
                variant="contained"
                size="large"
                disabled={
                  stakeInfo?.isWithdrawn ||
                  isLoadingWaitForTransaction ||
                  isLoadingWriteContract ||
                  isPrepareError
                }
              >
                {stakeInfo?.isWithdrawn ? 'Withdrawn' : 'Withdraw'}
              </Button>
            </Box>
          )}
        </Box>
        {isDownSm && (
          <Button
            onClick={() => withdraw?.()}
            variant="contained"
            size="medium"
            disabled={
              stakeInfo?.isWithdrawn ||
              isLoadingWaitForTransaction ||
              isLoadingWriteContract ||
              isPrepareError
            }
            fullWidth
            sx={{ mt: 3 }}
          >
            {stakeInfo?.isWithdrawn ? 'Withdrawn' : 'Withdraw'}
          </Button>
        )}
      </Box>
      <LoadingModal open={loadingModalOpen} />
      <SuccessModal
        title="Withdraw"
        content="Your Wtihdraw was Successful"
        open={successModalOpen}
        handleClose={() => setSuccessModalOpen(false)}
        hash={hash}
      />
    </>
  );
}
