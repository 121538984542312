import React from 'react';

import { Box } from '@mui/material';

import { Sidebar } from './styles';
import { SidebarNavigation } from './SidebarNavigation';
import { SidebarLogin } from './SidebarLogin';
import { Logo } from '../../../components';

export const SideBarNav = () => (
  <Sidebar>
    <Box>
      <Logo sx={{ mb: '3rem', mt: '2rem', ml: '1.12rem' }} />
      <SidebarNavigation />
    </Box>
    <SidebarLogin />
  </Sidebar>
);

export default SideBarNav;
