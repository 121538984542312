import clsx from 'clsx';
import CheckIcon from '@mui/icons-material/Check';
import { Avatar, CircularProgress, Skeleton } from '@mui/material';
import { useSnackbar } from 'notistack';
import { TWITTER_EVENT } from '../../../../../assets/img';
import { BlackButton } from '../../../../ui';
import { Api } from '../../../../../api';
import { useGetTasks } from '../../../../../api/queries/getTasks';

export function TaskCard({ data, handleInfoClick, hideInfo }) {
  const [trigger, { isFetching }] = Api.useLazyCheckTaskQuery();
  const { enqueueSnackbar } = useSnackbar();
  const { refetch } = useGetTasks();

  return (
    <div
      className={clsx(
        'flex flex-col gap-y-3 rounded-xl p-2',
        data?.done ? 'bg-primary' : 'bg-primary-dark'
      )}
    >
      <div className="w-full p-2 flex items-center gap-x-3 justify-between">
        <div className="flex items-center gap-x-4">
          <div className="flex justify-center items-center">
            <Avatar
              src={TWITTER_EVENT}
              className="w-9 h-9 md:w-11 md:!h-11 rounded-md"
            />
          </div>
          <div className="flex flex-col gap-y-2">
            <h6 className="text-sm font-bold">
              {data?.type === 'retweet' ? 'Retweet' : 'Tweet'}, check and claim
              your rewards{' '}
              {data?.collection && (
                <span>for {data.collection.replace('_', ' ')} collection</span>
              )}
              .
            </h6>
          </div>
        </div>
        {!hideInfo && (
          <div className="flex items-center gap-x-4">
            <BlackButton onClick={() => handleInfoClick(data?.content)}>
              show
            </BlackButton>
            {!data?.done && (
              <BlackButton
                className="flex items-center gap-x-2 min-h-[36px]"
                onClick={() =>
                  trigger({ id: data?.id })
                    .unwrap()
                    .then((data) => {
                      enqueueSnackbar(
                        `Task is ${
                          data.status ? 'completed' : 'not completed'
                        }`,
                        { variant: data.status ? 'success' : 'warning' }
                      );
                      if (data.status) {
                        refetch?.();
                      }
                    })
                }
              >
                {isFetching ? (
                  <CircularProgress className="text-white" size={15} />
                ) : (
                  'Check'
                )}
              </BlackButton>
            )}
            {data?.done && (
              <span className="w-5 h-5 flex items-center justify-center bg-success-light rounded-full">
                <CheckIcon className="!w-4 !h-4 text-white" />
              </span>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export function TaskCardSkeleton() {
  return (
    <div
      className={clsx(
        'flex flex-col gap-y-3 rounded-xl p-2',
        'bg-primary-dark'
      )}
    >
      <div className="w-full p-2 flex items-center justify-between">
        <div className="flex items-center gap-x-4">
          <div className="flex justify-center items-center">
            <Skeleton
              className="w-9 h-9 md:w-11 md:!h-11 rounded-md"
              variant="rectangular"
            />
          </div>
          <div className="flex flex-col gap-y-2">
            <Skeleton variant="text" className="text-sm w-20 font-bold" />
            <div className="flex flex-col sm:flex-row sm:items-center gap-y-1 sm:gap-y-0 sm:gap-x-2 text-xs">
              <div className="flex items-center gap-x-1">
                {/* <button
                type="button"
                onClick={() => handleInfoClick(data?.content)}
              >
                <InfoIcon className="!w-4 !h-4" />
              </button> */}
                <Skeleton variant="text" className="w-10" />
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center gap-x-4">
          <Skeleton variant="circular" className="w-5 h-5" />
        </div>
      </div>
    </div>
  );
}
