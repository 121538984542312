import { createAsyncThunk } from '@reduxjs/toolkit';
import { setCookie } from '../../utils/cookies';
import { customAxios } from '../../api/axios/customAxios';

export const authThunk = createAsyncThunk(
  'auth',
  // eslint-disable-next-line no-unused-vars
  async (wallet, { rejectWithValue }) => {
    try {
      const { data } = await customAxios.post('/user/auth', { wallet });
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const verifyAuthThunk = createAsyncThunk(
  'verify-auth',
  // eslint-disable-next-line no-unused-vars
  async (body, { rejectWithValue, getState }) => {
    const { token, clientAddress } = getState().auth;
    try {
      const { data } = await customAxios.post('/user/auth-verify', body, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });

      setCookie(
        'verify',
        JSON.stringify({
          token: data?.token,
          active: data?.active,
          address: clientAddress,
        }),
        6
      );

      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getUsersThunk = createAsyncThunk(
  'get-users',
  // eslint-disable-next-line no-unused-vars
  async (_, { rejectWithValue, getState }) => {
    const { verifyToken } = getState().auth;
    try {
      const { data } = await customAxios.get('/user', {
        headers: {
          authorization: `Bearer ${verifyToken}`,
        },
      });
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const notificationThunk = createAsyncThunk(
  'notifications',
  // eslint-disable-next-line no-unused-vars
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await customAxios.get('/notifications');
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
