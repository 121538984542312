import { Avatar, Box, Typography, styled } from '@mui/material';
import { pxToRem } from '../../../utils/getFontValue';
import { Logo } from '../../../assets/img';

export function StakingCards() {
  return (
    <Box display="flex" justifyContent="center">
      <StakeCard />
    </Box>
  );
}

function StakeCard() {
  return (
    <StyledStakeCard>
      <Typography variant="h3" align="center">
        20% APY
      </Typography>
      {/* <Box display="flex" alignItems="center" columnGap={2}>
        <GodlMedaIcon sx={{ width: pxToRem(64), height: pxToRem(64) }} />
        <Box>
          <Typography variant="h5" lineHeight={0.7}>
            Gold
          </Typography>
          <Typography variant="caption">12 months</Typography>
        </Box>
      </Box> */}
      <Box mt={4} display="flex" flexDirection="column" rowGap={2}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          columnGap={1}
        >
          <Avatar src={Logo} />
        </Box>
      </Box>
    </StyledStakeCard>
  );
}

const StyledStakeCard = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  background: theme.palette.grey['900'],
  padding: '1rem',
  borderRadius: theme.spacing(1),
  minWidth: pxToRem(260),
}));
