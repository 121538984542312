import {
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from 'wagmi';
import { addresses, getBigNumber } from '../utils';

const BWJ_ADDRESS = '0x83F41c98d028842Ccc8060b4Ec7738Df3eb9a2e6';

export const useApproveToken = ({
  tokenContractAddress,
  secondTokenSymbol,
  abi,
  onSuccessTransaction,
}) => {
  const {
    config,
    error: prepareError,
    isError: isPrepareError,
  } = usePrepareContractWrite({
    address: tokenContractAddress,
    abi,
    functionName: 'approve',
    args: [
      tokenContractAddress?.toLowerCase() === BWJ_ADDRESS?.toLowerCase() &&
      secondTokenSymbol === 'WOJ'
        ? addresses.wojSwapRouter
        : addresses.pancakeSwapRouterV2,
      getBigNumber(`${999 * 10 ** 18}`, 9),
    ],
    enabled: Boolean(tokenContractAddress),
  });

  const {
    data: approveData,
    isLoading: isLoadingWriteContract,
    isSuccess: isSuccessWriteContract,
    write,
  } = useContractWrite(config);

  const {
    isLoading: isLoadingWaitForTransaction,
    data: transactionReceipt,
    isSuccess: isSuccessTransaction,
    isError: isErrorTransaction,
    error: errorTransaction,
  } = useWaitForTransaction({
    hash: approveData?.hash,
    onSuccess(data) {
      onSuccessTransaction?.(data);
    },
  });

  return {
    prepareError,
    isPrepareError,
    isSuccessWriteContract,
    write,
    transactionReceipt,
    isSuccessTransaction,
    isErrorTransaction,
    errorTransaction,
    isLoading: isLoadingWriteContract || isLoadingWaitForTransaction,
  };
};
