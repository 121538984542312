import { useMemo } from 'react';
import { configureChains, createClient, WagmiConfig } from 'wagmi';
import { bsc } from '@wagmi/chains';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet';
import { InjectedConnector } from 'wagmi/connectors/injected';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';

import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { publicProvider } from 'wagmi/providers/public';
// eslint-disable-next-line import/no-unresolved
import { getDefaultClient } from 'connectkit';
import { bscTestnet } from 'wagmi/chains';
import {
  ALCHEMY_KEY as alchemyId,
  ENABLE_TESTNETS,
  PROJECT_ID as projectId,
} from '../../constants';

const WagmiProvider = ({ children }) => {
  const isTestnet = ENABLE_TESTNETS === 'true';

  const allowedChains = useMemo(
    () => (isTestnet ? [bsc, bscTestnet] : [bsc]),
    [isTestnet]
  );

  const { provider, chains } = configureChains(allowedChains, [
    alchemyProvider({ apiKey: alchemyId }),
    publicProvider(),
  ]);

  const client = createClient(
    getDefaultClient({
      // Required API Keys

      alchemyId,
      walletConnectProjectId: projectId,
      chains,
      provider,
      appName: 'Wojakians Platform',
      appUrl: 'https://app.woj.finance',
      appIcon: '%PUBLIC_URL%/woj.png',
      autoConnect: true,
      connectors: [
        new InjectedConnector({
          chains,
          options: {
            name: 'Injected',
            shimDisconnect: true,
          },
        }),
        new MetaMaskConnector({ chains }),
        new WalletConnectConnector({
          chains,
          options: {
            projectId,
            showQrModal: false,
            qrModalOptions: {
              enableExplorer: false,
            },
          },
        }),
        new CoinbaseWalletConnector({
          chains,
          options: {
            appName: 'Wojakians',
          },
        }),
      ],
    })
  );

  return (
    <>
      <WagmiConfig client={client}>{children}</WagmiConfig>
    </>
  );
};

export default WagmiProvider;
